<template>
  <div class="container">
    <Button
      label="All"
      icon="pi pi-copy"
      @click="copyToClipboard(clonesCommand)"
      v-tooltip.right="'Copy to clipboard'"
      iconPos="right"
    />
  </div>
</template>
<script>
export default {
  name: "ArenaCloneAll",
  props: {
    clones: String,
  },
  methods: {
    copyToClipboard(_text) {
      const el = document.createElement("textarea");
      el.value = _text;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
  },
  computed: {
    clonesCommand() {
      // this.clones is an array. we need to join it into a string separated by ;
      let command = "";
      for (let i = 0; i < this.clones.length; i++) {
        command += this.clones[i].command + "; ";
      }
      return command;
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  margin: 10px;
  padding: 2px;
}
</style>
