<template>
  <div class="payments">
    <Navbar />

    <div class="card">
      <main>
        <h1>Payment</h1>

        <p>
          Enable more payment method types
          <a href="https://dashboard.stripe.com/settings/payment_methods" target="_blank"
            >in your dashboard</a
          >.
        </p>

        <form id="payment-form" @submit.prevent="handleSubmit">
          <div id="link-authentication-element" />
          <div id="payment-element" />
          <button id="submit" :disabled="isLoading">Pay now</button>
          <sr-messages :messages="messages" />
        </form>
      </main>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { loadStripe } from "@stripe/stripe-js";
import Navbar from "@/components/core/Navbar";
import SrMessages from "@/components/payments/SrMessages.vue";

let stripe;
let elements;

export default {
  name: "Payment",
  components: {
    Navbar,
    SrMessages,
  },
  data() {
    return {
      isLoading: false,
      messages: [],
    };
  },
  computed: {
    ...mapGetters(["stripePublicKey", "stripePaymentIntent"]),
  },
  async mounted() {
    console.log("mounted");
    try {
      console.log(this.stripePublicKey);

      stripe = await loadStripe(this.stripePublicKey);

      console.log(this.stripePaymentIntent);

      elements = stripe.elements({ clientSecret: this.stripePaymentIntent });
      const paymentElement = elements.create("payment");
      paymentElement.mount("#payment-element");
      const linkAuthenticationElement = elements.create("linkAuthentication");
      linkAuthenticationElement.mount("#link-authentication-element");
      this.isLoading = false;
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle/report error
      this.messages.value.push(error.data);
    }
  },
  methods: {
    async handleSubmit() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/confirm`,
        },
      });

      if (error.type === "card_error" || error.type === "validation_error") {
        this.messages.push(error.message);
      } else {
        this.messages.push("An unexpected error occured.");
      }

      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  // background: #ffffff;
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 2rem;
}

// .p-dropdown {
//   width: 14rem;
//   font-weight: normal;
// }

// .product-name {
//   font-size: 1.5rem;
//   font-weight: 700;
// }

// .cart-header {
//   font-size: 1.5rem;
//   font-weight: 700;
// }

// .cart-total {
//   font-size: 1.5rem;
//   font-weight: 700;
// }

// .product-description {
//   margin: 0 0 1rem 0;
// }

// .product-category-icon {
//   vertical-align: middle;
//   margin-right: 0.5rem;
// }

// .product-category {
//   font-weight: 600;
//   vertical-align: middle;
// }

// ::v-deep(.product-list-item) {
//   display: flex;
//   align-items: center;
//   padding: 1rem;
//   width: 100%;

//   img {
//     width: 50px;
//     box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
//     margin-right: 2rem;
//   }

//   .product-list-detail {
//     flex: 1 1 0;
//   }

//   .p-rating {
//     margin: 0 0 0.5rem 0;
//   }

//   .product-price {
//     font-size: 1.5rem;
//     font-weight: 600;
//     margin-bottom: 0.5rem;
//     align-self: flex-end;
//   }

//   .product-list-action {
//     display: flex;
//     flex-direction: column;
//   }

//   .p-button {
//     margin-bottom: 0.5rem;
//   }
// }

// ::v-deep(.product-grid-item) {
//   margin: 0.5rem;
//   border: 1px solid var(--surface-border);

//   .product-grid-item-top,
//   .product-grid-item-bottom {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//   }

//   img {
//     box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
//     margin: 2rem 0;
//   }

//   .product-grid-item-content {
//     text-align: center;
//   }

//   .product-price {
//     font-size: 1.5rem;
//     font-weight: 600;
//   }
// }

// @media screen and (max-width: 576px) {
//   .product-list-item {
//     flex-direction: column;
//     align-items: center;

//     img {
//       margin: 2rem 0;
//     }

//     .product-list-detail {
//       text-align: center;
//     }

//     .product-price {
//       align-self: center;
//     }

//     .product-list-action {
//       display: flex;
//       flex-direction: column;
//     }

//     .product-list-action {
//       margin-top: 2rem;
//       flex-direction: row;
//       justify-content: space-between;
//       align-items: center;
//       width: 100%;
//     }
//   }
// }
</style>
