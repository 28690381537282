<template>
  <div class="faq mb-3">
    <Panel :header="question" toggleable :collapsed="collapsed">
      <p class="m-0 faq-answer">
        {{ answer }}
      </p>
    </Panel>
  </div>
</template>
<script>
export default {
  name: "Faq",
  props: {
    question: {
      type: String,
      required: true,
    },
    answer: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      collapsed: true,
    };
  },
};
</script>
<style scoped lang="scss">
.faq {
  display: block;
  // margin-bottom: 1rem;
}
</style>
