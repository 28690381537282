<template>
  <Toast />
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "SystemMessages",
  computed: {
    ...mapGetters(["message"]),
  },
  methods: {
    oidcError: function (e) {
      console.log("window: oidc oidcError event in vuex-oidc", e.detail);
      if (e.detail.context === "authenticateOidcSilent") {
        // do nothing as automaticSilentRenewError will warn the user
        return;
      }
      this.$store.dispatch(
        "handleError",
        "An authentication error occurred. Please try again."
      );
    },
    automaticSilentRenewError: function (e) {
      console.log("window: automaticSilentRenewError event in vuex-oidc", e.detail);
      this.$store.dispatch(
        "handleWarning",
        "Could not renew token. Please log in again."
      );
    },
  },
  watch: {
    message: {
      handler: function (msg) {
        console.log("message changed", msg);
        this.$toast.add({
          severity: msg.severity,
          summary: msg.summary,
          detail: msg.detail,
          life: 3000,
        });
      },
    },
  },
  mounted() {
    window.addEventListener("vuexoidc:oidcError", this.oidcError);
    window.addEventListener(
      "vuexoidc:automaticSilentRenewError",
      this.automaticSilentRenewError
    );
  },
  unmounted() {
    window.removeEventListener("vuexoidc:oidcError", this.oidcError);
    window.removeEventListener(
      "vuexoidc:automaticSilentRenewError",
      this.automaticSilentRenewError
    );
  },
};
</script>

<style scoped lang="scss">
.gamedays-navbar {
  height: calc(100% - 780px);
  background: linear-gradient(
    180deg,
    rgba(85, 48, 152, 0.3) 0%,
    rgba(83, 48, 152, 0) 100%
  );
  background-color: #0d1117;
  border-radius: 20px;
  align-items: center;
}
.blue {
  background-color: blue;
  color: white;
}
</style>
