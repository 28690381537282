<template>
  <footer>
    <div class="p-component">
      Git Hash: {{ gitHash }}

      <Button label="Credits" icon="pi pi-external-link" @click="visible = true" />

      <Dialog v-model:visible="visible" modal header="Credits" :style="{ width: '50vw' }">
        <div class="credits">
          <div class="credits-item">
            <img src="icons/trophy-32.png" />
            <a href="https://www.flaticon.com/free-icons/trophy" title="trophy icons"
              >Trophy icons created by Freepik - Flaticon</a
            >
          </div>
          <div class="credits-item">
            <img src="icons/controller-32.png" />
            <a href="https://www.flaticon.com/free-icons/joystick" title="joystick icons"
              >Joystick icons created by Freepik - Flaticon</a
            >
          </div>
          <div class="credits-item">
            <img src="icons/certificate-32.png" />
            <a href="https://www.flaticon.com/free-icons/patent" title="patent icons"
              >Patent icons created by Freepik - Flaticon</a
            >
          </div>
          <div class="credits-item">
            <img src="icons/interview1-32.png" />
            <a href="https://www.flaticon.com/free-icons/request" title="request icons"
              >Request icons created by Freepik - Flaticon</a
            >
          </div>
          <div class="credits-item">
            <img src="icons/interview2-32.png" />
            <a href="https://www.flaticon.com/free-icons/meeting" title="meeting icons"
              >Meeting icons created by Freepik - Flaticon</a
            >
          </div>
          <div class="credits-item">
            <img src="icons/team-32.png" />
            <a
              href="https://www.flaticon.com/free-icons/leadership"
              title="leadership icons"
              >Leadership icons created by Freepik - Flaticon</a
            >
          </div>
          <div class="credits-item">
            <img src="icons/person-32.png" />
            <a href="https://www.flaticon.com/free-icons/person" title="person icons"
              >Person icons created by Freepik - Flaticon</a
            >
          </div>
          <div class="credits-item">
            <img src="icons/devops-32.png" />
            <a href="https://www.flaticon.com/free-icons/devops" title="devops icons"
              >Devops icons created by Freepik - Flaticon</a
            >
          </div>
          <div class="credits-item">
            <img src="icons/infinity-32.png" />
            <a href="https://www.flaticon.com/free-icons/infinity" title="infinity icons"
              >Infinity icons created by Freepik - Flaticon</a
            >
          </div>
          <div class="credits-item">
            <img src="icons/rocket-32.png" />
            <a href="https://www.flaticon.com/free-icons/deploy" title="deploy icons"
              >Deploy icons created by Freepik - Flaticon</a
            >
          </div>
          <div class="credits-item">
            <img src="icons/headphones1-32.png" />
            <a
              href="https://www.flaticon.com/free-icons/headphones"
              title="headphones icons"
              >Headphones icons created by Freepik - Flaticon</a
            >
          </div>
          <div class="credits-item">
            <img src="icons/headphones2-32.png" />
            <a href="https://www.flaticon.com/free-icons/sound" title="sound icons"
              >Sound icons created by Freepik - Flaticon</a
            >
          </div>
          <div class="credits-item">
            <img src="icons/support-32.png" />
            <a
              href="https://www.flaticon.com/free-icons/customer-service"
              title="customer service icons"
              >Customer service icons created by Freepik - Flaticon</a
            >
          </div>
        </div>
      </Dialog>
    </div>
    <!-- <div class="attribution">
      <a href="https://www.flaticon.com/free-icons/headphone" title="headphone icons">Headphone icons created by rukanicon
        - Flaticon</a>
      <div>
        Icons made by
        <a href="https://www.flaticon.com/authors/rukanicon" title="rukanicon">
          rukanicon
        </a>
        from
        <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com'</a>
      </div>
    </div> -->
    <Sidebar
      :baseZIndex="10000"
      position="bottom"
      :visible="showCookieBanner"
      :showCloseIcon="false"
      style="height: 8rem"
      class="p-component"
    >
      <div class="flex flex-wrap align-items-center">
        <span class="m-2 cookies-notice">
          This site uses cookies and similar technologies to improve your experience. We
          also use <a href="https://www.stripe.com">Stripe</a> pre-build component for
          payments. See
          <a href="https://stripe.com/gb/legal/cookies-policy">Stripe</a> cookie
          policy.</span
        >
        <Button
          icon="pi pi-fw pi-thumbs-up"
          label="Accept"
          @click="setAcceptCookies(true)"
          severity="success"
          class="m-1 cookies-button"
        />
      </div>
    </Sidebar>
  </footer>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Footer",

  computed: {
    ...mapGetters(["acceptCookies"]),
    showCookieBanner() {
      console.log("is cookies accepted: " + this.acceptCookies);
      return !this.acceptCookies;
    },
  },
  created() {
    //load from local storage
    this.$store.dispatch("getAgreements");
  },
  methods: {
    ...mapActions(["setAcceptCookies"]),
  },
  data() {
    return {
      visible: false,
      gitHash: process.env.VUE_APP_GIT_HASH,
    };
  },
};
</script>

<style scoped lang="scss">
.cookie-button {
  width: 6.5rem;
}
.cookies-notice {
  font-size: 0.9rem;
}
@media only screen and (max-width: 960px) {
  .cookie-button {
    width: 6rem;
    font-size: 0.8rem;
  }
  .cookies-notice {
    font-size: 0.7rem;
  }
}


div.attribution {
  //make smaller
  font-size: 0.5rem;
  color: #202021;
}

.credits {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    margin: 0.5rem;
    color: var(--text-color);
  }

  .credits-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    img {
      margin: 0.5rem;
    }
  }
}
</style>
