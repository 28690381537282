import axios from 'axios';
import { apiSettings } from '@/config/api'
import router from '@/router'

const state = {
  gameId: null,
  gameProgress: null,
  gameScore: null,
  gameScoreTrend: null,
  gameOnlineUsers: null,
  gameRPS: null,
  gameEPS: null,
  //"NotInterestedUser", "FreeUser", "PremiumUser", "AntiGravBoardsUser", "BlueFusionUser"
  gameOnlineUserRatios: {
    "NotInterestedUser": 0,
    "FreeUser": 0,
    "PremiumUser": 0,
    "AntiGravBoardsUser": 0,
    "BlueFusionUser": 0
  },
  gameInterval: null,
  gameChats: [],
  gameSelectedContact: null,
  gameContactMessages: [],
  gameCloneCommands: [],
}

const mutations = {
  UPDATE_GAME_ID(state, payload) {
    state.gameId = payload;
  },
  UPDATE_GAME_PROGRESS(state, payload) {
    state.gameProgress = payload;
  },
  UPDATE_GAME_SCORE(state, payload) {
    state.gameScore = payload;
  },
  UPDATE_GAME_SCORE_TREND(state, payload) {
    state.gameScoreTrend = payload;
  },
  UPDATE_GAME_ONLINE_USERS(state, payload) {
    state.gameOnlineUsers = payload;
  },
  UPDATE_GAME_RPS(state, payload) {
    state.gameRPS = payload;
  },
  UPDATE_GAME_EPS(state, payload) {
    state.gameEPS = payload;
  },
  UPDATE_GAME_ONLINE_USER_RATIOS(state, payload) {

    // const user_types = ["NotInterestedUser", "FreeUser", "PremiumUser", "AntiGravBoardsUser", "BlueFusionUser"]

    // if (state.gameOnlineUserRatios == null) {
    //   state.gameOnlineUserRatios = []
    // }

    // payload looks like this
    //   "ratios": [
    //     {
    //         "user_type": "NotInterestedUser",
    //         "ratio": 0.05263157894736842
    //     },
    //     {
    //         "user_type": "FreeUser",
    //         "ratio": 0.3157894736842105
    //     },
    //     {
    //         "user_type": "PremiumUser",
    //         "ratio": 0.3157894736842105
    //     },
    //     {
    //         "user_type": "AntiGravBoardsUser",
    //         "ratio": 0.0
    //     },
    //     {
    //         "user_type": "BlueFusionUser",
    //         "ratio": 0.3157894736842105
    //     }
    // ]

    var user_types = Object.keys(state.gameOnlineUserRatios)

    // loop throught the user types and extract the ratio from the payload
    user_types.forEach(user_type => {
      console.log(user_type)
      var found = false
      payload.forEach(ratio => {
        console.log(ratio)
        if (ratio.user_type == user_type) {
          found = true
          var percent = Math.round(ratio.ratio * 100)
          state.gameOnlineUserRatios[user_type] = percent
        }
      })
      if (!found) {
        state.gameOnlineUserRatios[user_type] = 0
      }
    })

    console.log(state.gameOnlineUserRatios)




    //   for (var i = 0; i < user_types.length; i++) {
    //   var found = false
    //   for (var j = 0; j < payload.length; j++) {
    //     if (payload[j].user_type == user_types[i]) {
    //       found = true
    //       var percent = Math.round(payload[j].ratio * 100)
    //       state.gameOnlineUserRatios[i] = percent
    //     }
    //   }
    //   if (!found) {
    //     state.gameOnlineUserRatios[i] = 0
    //   }
    // }
  },
  UPDATE_GAME_CHATS(state, payload) {
    state.gameChats = payload;
  },
  UPDATE_GAME_CONTACT_MESSAGES(state, payload) {
    state.gameContactMessages = payload;
  },
  UPDATE_GAME_STATE_INTERVAL(state, interval) {
    state.gameInterval = interval;
  },
  UPDATE_GAME_SELECTED_CONTACT(state, interval) {
    state.gameSelectedContact = interval;
  },
  UPDATE_GAME_CLONE_COMMANDS(state, payload) {
    state.gameCloneCommands = payload;
  }

}
const actions = {
  setGameId({ commit }, payload) {
    commit('UPDATE_GAME_ID', payload);
  },

  getGameCloneCommands({ commit, state, rootState, dispatch }) {
    console.log("getting game clone commands for game id: " + state.gameId)
    const user = rootState.oidcStore.user
    console.log(`user email: ${user.email}`)
    const safe_email = encodeURIComponent(user.email)
    axios.get(`${apiSettings.gamesdays_core_game_api}/${state.gameId}/secret/${safe_email}`).then((response) => {
      var gitea_player_access_token = response.data.gitea_player_access_token
      var cloneCommands = []
      cloneCommands.push({ "name": "bash-as-a-service-entry-page", "command": `git clone https://${gitea_player_access_token}@gitea.${state.gameId}.gamedays.aw125.co.uk/BASHaaS/bash-as-a-service-entry-page.git` })
      cloneCommands.push({ "name": "bash-as-a-service-api", "command": `git clone https://${gitea_player_access_token}@gitea.${state.gameId}.gamedays.aw125.co.uk/BASHaaS/bash-as-a-service-api.git` })
      cloneCommands.push({ "name": "bash-as-a-service-jenkins-shared-lib", "command": `git clone https://${gitea_player_access_token}@gitea.${state.gameId}.gamedays.aw125.co.uk/BASHaaS/bash-as-a-service-jenkins-shared-lib.git` })
      cloneCommands.push({ "name": "bash-as-a-service-db", "command": `git clone https://${gitea_player_access_token}@gitea.${state.gameId}.gamedays.aw125.co.uk/BASHaaS/bash-as-a-service-db.git` })
      cloneCommands.push({ "name": "bash-as-a-service-frontend-custom", "command": `git clone https://${gitea_player_access_token}@gitea.${state.gameId}.gamedays.aw125.co.uk/BASHaaS/bash-as-a-service-frontend-custom.git` })
      cloneCommands.push({ "name": "bash-as-a-service-frontend-premium", "command": `git clone https://${gitea_player_access_token}@gitea.${state.gameId}.gamedays.aw125.co.uk/BASHaaS/bash-as-a-service-frontend-premium.git` })
      cloneCommands.push({ "name": "bash-as-a-service-frontend-free", "command": `git clone https://${gitea_player_access_token}@gitea.${state.gameId}.gamedays.aw125.co.uk/BASHaaS/bash-as-a-service-frontend-free.git` })

      commit('UPDATE_GAME_CLONE_COMMANDS', cloneCommands);
    }).catch((error) => {
      console.log(error)
      dispatch('handleError', "Failed to get the clone commands. Please retry", { root: true })
    });
  },

  setSelectedContact({ commit }, payload) {
    commit('UPDATE_GAME_SELECTED_CONTACT', payload);
  },

  getGameState({ commit, state, dispatch }) {
    console.log("getting game state for game id: " + state.gameId)
    axios.get(`${apiSettings.gamesdays_core_game_api}/${state.gameId}`).then((response) => {
      commit('UPDATE_GAME_PROGRESS', response.data.completion);
      commit('UPDATE_GAME_SCORE', response.data.score);
      commit('UPDATE_GAME_SCORE_TREND', response.data.score_trend);
      commit('UPDATE_GAME_ONLINE_USERS', response.data.online);
      commit('UPDATE_GAME_RPS', response.data.rps);
      commit('UPDATE_GAME_EPS', response.data.eps);
      commit('UPDATE_GAME_ONLINE_USER_RATIOS', response.data.ratios);
    }).catch((error) => {
      console.log(error)
      dispatch('handleWarning', "Failed to get the game state. Retrying...", { root: true })
    });
  },
  getGameChats({ commit, state, dispatch }) {
    console.log("getting game chat messages for game id: " + state.gameId)
    axios.get(`${apiSettings.gamesdays_core_game_api}/${state.gameId}/chat/`).then((response) => {
      commit('UPDATE_GAME_CHATS', response.data);
      dispatch('openFirstChat');
    }).catch((error) => {
      console.log(error)
      dispatch('handleWarning', "Failed to get the chats. Retrying...", { root: true })
    });
  },
  sendGameChatMessage({ dispatch, state, rootState }, payload) {
    const user = rootState.oidcStore.user
    console.log(`user email: ${user.email}`)
    console.log(`text message: ${payload.text}`)

    var now = new Date();

    var newMessage = {
      "sender": user.email,
      "text": payload.text,
      "read": true, //We dont want to show our own messages as unread
      "timestamp": now.toISOString()
    }
    payload = null

    //create an array with the existing messages and apeend the new message
    var messages = state.gameContactMessages
    messages.push(newMessage)

    var patch = {
      "messages": messages,
      // "status": "Online" WHy is this here?
    }

    axios.patch(`${apiSettings.gamesdays_core_game_api}/${state.gameId}/chat/${state.gameSelectedContact}`, patch).then((response) => {
      console.log(response)
      dispatch('getGameChats')
    });

  },
  openGame({ dispatch }, game_id) {
    console.log(game_id)
    dispatch('setGameId', game_id) //Should we set this here or rely on the route?
    router.push({ path: `/arena/${game_id}` })
  },
  openChat({ dispatch, state, commit }, contact) {
    console.log(contact)
    dispatch('setSelectedContact', contact)
    //dispatch('getGameChats')
    //loop through chats and find the contact
    //if found, set the contact messages
    //if not found, set the contact messages to empty
    var found = false
    for (var i = 0; i < state.gameChats.length; i++) {
      if (state.gameChats[i].contact == contact) {
        found = true
        var messages = state.gameChats[i].messages
        for (var j = 0; j < messages.length; j++) {
          messages[j].read = true
        }
        var patch = {
          "messages": messages,
          // "status": "Online" WHy is this here?
        }

        axios.patch(`${apiSettings.gamesdays_core_game_api}/${state.gameId}/chat/${state.gameSelectedContact}`, patch).then((response) => {
          console.log(response)
          dispatch('getGameChats')
        }).catch((error) => {
          console.log(error)
          dispatch('handleWarning', "Failed to update chat status. Please retry...", { root: true })
        });
        commit('UPDATE_GAME_CONTACT_MESSAGES', messages)
      }
    }
    if (!found) {
      console.log("no messages found for contact: " + contact)
      commit('UPDATE_GAME_CONTACT_MESSAGES', [])
    }
  },
  openFirstChat({ dispatch, state }) {
    //Only open the first chat if there is one and we do not have a selected contact

    if (state.gameSelectedContact == null) {
      console.log("no contact selected, opening first chat")
      console.log(state.gameChats)
      if (state.gameChats.length > 0) {
        console.log("opening chat for contact: " + state.gameChats[0].contact)
        dispatch('openChat', state.gameChats[0].contact)
      } else {
        console.log("no chats found")
      }
    } else {
      console.log("contact already selected")
    }
  },
  // every 5s dispatch actions which updates the game progress and score
  startUpdateGameState({ dispatch, state, commit }) {
    console.log("start updating game state")
    if (state.gameId !== null && state.gameInterval === null) {
      var stateInterval = setInterval(() => {
        dispatch('getGameState')
        dispatch('getGameChats')
        dispatch('getGameCloneCommands')  // bug where sometimes the clone commands have wrong api key

      }, 5000);
      commit('UPDATE_GAME_STATE_INTERVAL', stateInterval)
    } else {
      console.log("no game id set or update already running")
    }
  },
  stopUpdateGameState({ state, commit }) {
    console.log("stop updating game state")
    clearInterval(state.gameInterval)
    commit('UPDATE_GAME_STATE_INTERVAL', null)
  },

}
const getters = {
  gameId: state => state.gameId,
  gameProgress: state => state.gameProgress,
  gameScore: state => state.gameScore,
  gameScoreTrend: state => state.gameScoreTrend,
  gameOnlineUsers: state => state.gameOnlineUsers,
  gameRPS: state => state.gameRPS,
  gameEPS: state => state.gameEPS,
  gameOnlineUserRatios: state => state.gameOnlineUserRatios,
  gameChats: state => state.gameChats,
  gameContactMessages: state => state.gameContactMessages,
  gameSelectedContact: state => state.gameSelectedContact,
  gameCloneCommands: state => state.gameCloneCommands,
}
const arenaModule = {
  state,
  mutations,
  actions,
  getters
}

export default arenaModule;