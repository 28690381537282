import axios from 'axios';
import { apiSettings } from '../../../config/api'
const state = {
  productItems: []
}
const mutations = {
  UPDATE_PRODUCT_ITEMS(state, payload) {
    state.productItems = payload;
  }
}
const actions = {
  getProductItems({ commit, dispatch }) {
    console.log(`${apiSettings.gamesdays_core_product_api}`)
    axios.get(`${apiSettings.gamesdays_core_product_api}`).then((response) => {
      commit('UPDATE_PRODUCT_ITEMS', response.data)
    }).catch((error) => {
      console.log(error)
      dispatch('handleError', "Failed to get products. Please try again.", { root: true })
    });
  }
}
const getters = {
  productItems: state => state.productItems,
  productItemById: (state) => (product_id) => {
    return state.productItems.find(productItem => productItem.product_id === product_id)
  }
}
const productModule = {
  state,
  mutations,
  actions,
  getters
}

export default productModule;