<template>
  <Button class="btn3 p-button-secondary" @click="launchTool(gameId, tool)" severity="contrast">
    <img :src="icon" :alt="tool" width="20" height="20" />
    <span>{{ text }}</span>
  </Button>
</template>
<script>
import jenkins_icon from "../../../public/tools/jenkins.svg";
import gitea_icon from "../../../public/tools/gitea.svg";
import nexus_icon from "../../../public/tools/nexus-white.png";
import grafana_icon from "../../../public/tools/grafana.svg";
import jaeger_icon from "../../../public/tools/jaeger.svg";
import terminal_icon from "../../../public/tools/terminal.png";
import test_icon from "../../../public/tools/test.drawio.png";
import prod_icon from "../../../public/tools/prod.drawio.png";
import dev_icon from "../../../public/tools/dev.drawio.png";

export default {
  name: "ArenaToolsButton2",
  props: {
    text: String,
    gameId: String,
    tool: String,
  },
  methods: {
    launchTool(_gameId, _tool) {
      // lookup the prefix for the tool
      var prefix = this.prefixes[_tool];
      // if there is a postfix, add it to the url

      var postfix = this.postfixes[_tool];
      var url = `https://${prefix}.${_gameId}.gamedays.aw125.co.uk/${postfix}`;
      window.open(url, "_blank");
    },
  },
  computed: {
    icon() {
      // check if tool is in the icons dictionary and return the icon. if not, return a default icon
      return this.icons[this.tool] ? this.icons[this.tool] : this.icons["jenkins"];
    },
  },
  data() {
    return {
      //dictionary of tool icons
      icons: {
        jenkins: jenkins_icon,
        gitea: gitea_icon,
        nexus: nexus_icon,
        grafana: grafana_icon,
        jaeger: jaeger_icon,
        terminal: terminal_icon,
        test: test_icon,
        prod: prod_icon,
        dev: dev_icon,
      },
      prefixes: {
        jenkins: "jenkins",
        gitea: "gitea",
        nexus: "nexus3",
        grafana: "grafana",
        jaeger: "jaeger",
        terminal: "guacamole",
        test: "test1-entry-bash-as-a-service",
        prod: "entry-bash-as-a-service",
        dev: "dev",
      },
      postfixes: {
        jenkins: "",
        gitea: "user/oauth2/gamedays",
        nexus: "",
        grafana: "login/generic_oauth",
        jaeger: "",
        terminal: "",
        test: "",
        prod: "",
        dev: "",
      },
    };
  },
};
</script>

<style scoped>
.btn3 img {
  margin-right: 8px;
}
.btn3{
  margin: 0 0.2rem;
  width: 6.5rem;
}
</style>
