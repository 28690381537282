<!-- TODO fix button spacing -->
<template>
  <div class="cart">
    <Navbar />

    <!-- <script async src="https://js.stripe.com/v3/buy-button.js"></script>

    <stripe-buy-button
      buy-button-id="buy_btn_1Oe2ZmGkEuKbAk0BcozFPtmc"
      publishable-key="pk_test_51Oe1HuGkEuKbAk0Beep0M54TpBUsGRdLwPf4KwV2NxQCGDaA4saPszIj0aQfwAcTOVZjupVOeRlepumOAUHmbUvs00T1TxcAOZ"
    >
    </stripe-buy-button> -->
    <div class="card">
      <DataView
        :value="cartItems"
        :layout="layout"
        :paginator="true"
        :rows="9"
        :sortOrder="sortOrder"
        :sortField="sortField"
      >
        <template #empty>Cart empty. Add some items from the store.</template>
        <template #header>
          <div class="grid grid-nogutter">
            <div class="col-6 cart-header" style="text-align: left">
              Cart
              <!-- <Dropdown
              v-model="sortKey"
              :options="sortOptions"
              optionLabel="label"
              placeholder="Sort By Price"
              @change="onSortChange($event)"
            /> -->
            </div>
            <div class="col-6" style="text-align: right">
              <DataViewLayoutOptions v-model="layout" />
            </div>
          </div>
        </template>

        <template #list="slotProps">
          <div class="col-12">
            <div class="product-list-item">
              <img
                :src="'products/' + slotProps.data.image_tag"
                :alt="slotProps.data.title"
              />
              <div class="product-list-detail">
                <div class="product-name">{{ slotProps.data.title }}</div>
                <div class="product-description">
                  {{ slotProps.data.description }}
                </div>
                <i class="pi pi-tag product-category-icon"></i
                ><span class="product-category">{{ slotProps.data.product_type }}</span>
              </div>
              <div class="product-list-action">
                <span class="product-price"
                  >£{{ slotProps.data.price / 100 }} x {{ slotProps.data.quantity }}
                </span>
                <Button
                  icon="pi pi-plus"
                  label="Add"
                  @click="addCartItem(slotProps.data)"
                ></Button>
                <Button
                  icon="pi pi-minus"
                  label="Remove"
                  @click="removeCartItem(slotProps.data.product_id)"
                ></Button>
              </div>
            </div>
          </div>
        </template>

        <template #grid="slotProps">
          <div class="col-12 md:col-4">
            <div class="product-grid-item card">
              <div class="product-grid-item-top">
                <div>
                  <i class="pi pi-tag product-category-icon"></i>
                  <span class="product-category">{{ slotProps.data.product_type }}</span>
                </div>
              </div>
              <div class="product-grid-item-content">
                <img
                  :src="'products/' + slotProps.data.image_tag"
                  :alt="slotProps.data.title"
                />
                <div class="product-name">{{ slotProps.data.title }}</div>
                <div class="product-description">
                  {{ slotProps.data.description }}
                </div>
              </div>
              <div class="product-grid-item-bottom">
                <span class="product-price"
                  >£{{ slotProps.data.price / 100 }} x {{ slotProps.data.quantity }}
                </span>
                <Button icon="pi pi-plus" @click="addCartItem(slotProps.data)"></Button>
                <Button
                  icon="pi pi-minus"
                  @click="removeCartItem(slotProps.data.product_id)"
                ></Button>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="grid grid-nogutter">
            <div class="col-6 cart-total" style="text-align: left">
              Total: £{{ cartTotal / 100 }}
            </div>
            <div class="col-6" style="text-align: right">
              <Button
                class="p-button-success mr-2"
                icon="pi pi-credit-card"
                label="Checkout"
                @click="createStripeCheckoutSessionAndOpenSession()"
                :disabled="!cartItems.length"
              ></Button>
              <Button
                icon="pi pi-trash"
                class="p-button-danger mr-2"
                label="Empty"
                @click="removeAllCartItems"
                :disabled="!cartItems.length"
              ></Button>
            </div>
            <div id="payment-element"></div>
            <div id="link-authentication-element"></div>
          </div>
        </template>
      </DataView>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
// import { loadStripe } from "@stripe/stripe-js";

import Navbar from "@/components/core/Navbar";

export default {
  name: "Cart",
  props: ["session_id"],
  components: {
    Navbar,
  },
  data() {
    return {
      layout: "list",
      sortKey: null,
      sortOrder: null,
      sortField: null,
      sortOptions: [
        { label: "Price High to Low", value: "!price" },
        { label: "Price Low to High", value: "price" },
      ],
    };
  },
  computed: {
    ...mapGetters(["cartItems", "cartTotal", "cartQuantity", "stripeTracker"]),
  },
  beforeCreate() {
    // TODO handle case where we have been redirected back from stripe with a session id
    console.log("cart beforeCreate");
    console.log("session_id: " + this.session_id);
    if (this.session_id != null) {
      console.log("session_id is not null");
      this.$store.dispatch("getStripeTracker", this.session_id);
    }
  },
  created() {
    this.$store.dispatch("getCartItems");
  },
  mounted() {
    // const stripe = loadStripe(this.stripePublicKey);
  },
  methods: {
    ...mapActions([
      "removeAllCartItems",
      // "checkoutCartItems",
      "addCartItem",
      "removeCartItem",
      "createStripeCheckoutSessionAndOpenSession",
      "getStripeTracker",
    ]),
    onSortChange(event) {
      const value = event.value.value;
      const sortValue = event.value;

      if (value.indexOf("!") === 0) {
        this.sortOrder = -1;
        this.sortField = value.substring(1, value.length);
        this.sortKey = sortValue;
      } else {
        this.sortOrder = 1;
        this.sortField = value;
        this.sortKey = sortValue;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  // background: #ffffff;
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 2rem;
}

// .p-dropdown {
//   width: 14rem;
//   font-weight: normal;
// }

.product-name {
  font-size: 1.5rem;
  font-weight: 700;
}

.cart-header {
  font-size: 1.5rem;
  font-weight: 700;
}

.cart-total {
  font-size: 1.5rem;
  font-weight: 700;
}

.product-description {
  margin: 0 0 1rem 0;
}

.product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.product-category {
  font-weight: 600;
  vertical-align: middle;
}

::v-deep(.product-list-item) {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;

  img {
    width: 50px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 2rem;
  }

  .product-list-detail {
    flex: 1 1 0;
  }

  // .p-rating {
  //   margin: 0 0 0.5rem 0;
  // }

  .product-price {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    align-self: flex-end;
  }

  .product-list-action {
    display: flex;
    flex-direction: column;
  }

  .p-button {
    margin-bottom: 0.5rem;
  }
}

::v-deep(.product-grid-item) {
  margin: 0.5rem;
  border: 1px solid var(--surface-border);

  .product-grid-item-top,
  .product-grid-item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  img {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 2rem 0;
  }

  .product-grid-item-content {
    text-align: center;
  }

  .product-price {
    font-size: 1.5rem;
    font-weight: 600;
  }
}

@media screen and (max-width: 576px) {
  .product-list-item {
    flex-direction: column;
    align-items: center;

    img {
      margin: 2rem 0;
    }

    .product-list-detail {
      text-align: center;
    }

    .product-price {
      align-self: center;
    }

    .product-list-action {
      display: flex;
      flex-direction: column;
    }

    .product-list-action {
      margin-top: 2rem;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}
</style>
