import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'



//import './../node_modules/bulma/css/bulma.css'

//CSS first as per https://github.com/primefaces/primevue/issues/1220
//import 'primevue/resources/themes/saga-blue/theme.css';
// import 'primevue/resources/themes/lara-dark-purple/theme.css';

// import 'primevue/resources/themes/arya-blue/theme.css'
// import 'primevue/resources/themes/arya-green/theme.css'
// import 'primevue/resources/themes/arya-orange/theme.css'
// import 'primevue/resources/themes/arya-purple/theme.css'
import 'primevue/resources/themes/bootstrap4-dark-blue/theme.css'  //noraally this one
// import 'primevue/resources/themes/bootstrap4-dark-purple/theme.css'
// import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
// import 'primevue/resources/themes/bootstrap4-light-purple/theme.css'
// import 'primevue/resources/themes/fluent-light/theme.css'
// like this import 'primevue/resources/themes/lara-dark-blue/theme.css'
// import 'primevue/resources/themes/lara-dark-indigo/theme.css'
// import 'primevue/resources/themes/lara-dark-purple/theme.css'
// import 'primevue/resources/themes/lara-dark-teal/theme.css'
// import 'primevue/resources/themes/lara-light-blue/theme.css'
// import 'primevue/resources/themes/lara-light-indigo/theme.css'
// import 'primevue/resources/themes/lara-light-purple/theme.css'
// import 'primevue/resources/themes/lara-light-teal/theme.css'
// import 'primevue/resources/themes/luna-amber/theme.css'
// import 'primevue/resources/themes/luna-blue/theme.css'
// import 'primevue/resources/themes/luna-green/theme.css'
// import 'primevue/resources/themes/luna-pink/theme.css'
// import 'primevue/resources/themes/mdc-dark-deeppurple/theme.css'
// import 'primevue/resources/themes/mdc-dark-indigo/theme.css'
// import 'primevue/resources/themes/mdc-light-deeppurple/theme.css'
// import 'primevue/resources/themes/mdc-light-indigo/theme.css'
// import 'primevue/resources/themes/md-dark-deeppurple/theme.css'
// import 'primevue/resources/themes/md-dark-indigo/theme.css'
// import 'primevue/resources/themes/md-light-deeppurple/theme.css'
// import 'primevue/resources/themes/md-light-indigo/theme.css'
// import 'primevue/resources/themes/nova/theme.css'
// import 'primevue/resources/themes/nova-accent/theme.css'
// like this one // import 'primevue/resources/themes/nova-alt/theme.css'
// import 'primevue/resources/themes/nova-vue/theme.css'
// import 'primevue/resources/themes/rhea/theme.css'
// import 'primevue/resources/themes/saga-blue/theme.css'
// import 'primevue/resources/themes/saga-green/theme.css'
// import 'primevue/resources/themes/saga-orange/theme.css'
// import 'primevue/resources/themes/saga-purple/theme.css'
// import 'primevue/resources/themes/tailwind-light/theme.css'
// import 'primevue/resources/themes/vela-blue/theme.css'
// import 'primevue/resources/themes/vela-green/theme.css'
// import 'primevue/resources/themes/vela-orange/theme.css'
//import 'primevue/resources/themes/vela-purple/theme.css'


import 'primevue/resources/primevue.min.css';
//import '../src/assets/_theme.scss'
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import Dropdown from 'primevue/dropdown';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Sidebar from 'primevue/sidebar';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import Divider from 'primevue/divider';
import Card from 'primevue/card';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import OrderList from 'primevue/orderlist';
import ScrollPanel from 'primevue/scrollpanel';
import InputText from 'primevue/inputtext';
import Toolbar from 'primevue/toolbar';
import Tooltip from 'primevue/tooltip';
//import Badge from 'primevue/badge';
import Panel from 'primevue/panel';
import BadgeDirective from 'primevue/badgedirective';
import InputSwitch from 'primevue/inputswitch';
import Avatar from 'primevue/avatar';
import MultiSelect from 'primevue/multiselect';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import Timeline from 'primevue/timeline';
import VirtualScroller from 'primevue/virtualscroller';
import SpeedDial from 'primevue/speeddial';
import Chart from 'primevue/chart';
import OverlayPanel from 'primevue/overlaypanel';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast'
import Menubar from 'primevue/menubar'
import Knob from 'primevue/knob';
import Tag from 'primevue/tag'
import Fieldset from 'primevue/fieldset'
import Chip from 'primevue/chip'
import Carousel from 'primevue/carousel'
import Rating from 'primevue/rating'





const app = createApp(App).use(store).use(router).use(PrimeVue);
app.use(ToastService);

//TODO: move to a global error handler

// app.config.errorHandler = (err, instance, info) => {
//     console.log("handling error..")
//     // handle error, e.g. report to a service
//     console.error(err, instance, info)
//     app.$toast.add({ severity: 'error', summary: 'Error', detail: err.message });
// }

app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('Dropdown', Dropdown);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
app.component('Sidebar', Sidebar);
app.component('Button', Button);
app.component('Menu', Menu);
app.component('Divider', Divider);
app.component('Card', Card);
app.component('ProgressBar', ProgressBar)
app.component('ProgressSpinner', ProgressSpinner)
app.component('OrderList', OrderList)
app.component('InputText', InputText)
app.component('ScrollPanel', ScrollPanel)
app.component('Toolbar', Toolbar)
app.component('InputSwitch', InputSwitch)
app.component('Panel', Panel)
app.component('Avatar', Avatar)
app.component('MultiSelect', MultiSelect)
app.component('TriStateCheckbox', TriStateCheckbox)
app.component('Timeline', Timeline)
app.component('VirtualScroller', VirtualScroller)
app.component('SpeedDial', SpeedDial)
app.component('Chart', Chart)
app.component('OverlayPanel', OverlayPanel)
app.component('Dialog', Dialog)
app.component('Calendar', Calendar)
app.component('Toast', Toast)
app.component('Menubar', Menubar)
app.component('Knob', Knob)
app.component('Tag', Tag)
app.component('Fieldset', Fieldset)
app.component('Chip', Chip)
app.component('Carousel', Carousel)
app.component('Rating', Rating)


app.directive('badge', BadgeDirective);
app.directive('tooltip', Tooltip);

app.mount('#app')


