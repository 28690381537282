<template>
  <div class="home">
    <Navbar />
    <Landing />
    <LeaderboardPublic />
    <Footer />
  </div>
</template>

<script>
import { mapActions } from "vuex";
// @ is an alias to /src
import Navbar from "@/components/core/Navbar";
import LeaderboardPublic from "@/components/leaderboard/LeaderboardPublic.vue";
import Landing from "@/components/landing/Landing.vue";
import Footer from "@/components/core/Footer.vue";

export default {
  name: "Home",
  components: {
    LeaderboardPublic,
    Landing,
    Navbar,
    Footer,
  },
  methods: {
    ...mapActions(["handleError", "handleWarning", "handleInfo", "handleSuccess"]),
  },
};
</script>
<style scoped lang="scss">
.home {
  display: block;
}
</style>
