<template>
  <div class="card">
    <DataTable :value="compLeaderboardPublic" :paginator="true" class="p-datatable-customers" :rows="10" dataKey="id"
      v-model:filters="filters" filterDisplay="row" responsiveLayout="scroll" :globalFilterFields="['team_name']">
      <template #empty>No scores found.</template>
      <Column field="team_name" header="Name" style="min-width: 12rem">
        <template #body="{ data }">
          <span class="team-name">{{ data.team_name }}</span>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter"
            v-tooltip.top.focus="'Hit enter key to filter'" />
        </template>
      </Column>
      <Column header="Rank" style="min-width: 12rem">
        <template #body="{ data }">
          <span class="rank">{{ data.rank }}</span>
          <i :class="'updown-icon ' + iconRank(data.rank, data.rank_last)"></i>
        </template>
      </Column>
      <!-- <Column header="Previous" style="min-width: 12rem">
        <template #body="{ data }">
          <span :class="formatRank(data.rank, data.rank_last)">{{
            data.rank_last
          }}</span>
          <i :class="iconRank(data.rank, data.rank_last)"></i>
        </template>
      </Column> -->
      <Column header="Trend" style="min-width: 12rem">
        <template #body="{ data }">
          <span :class="formatTrend(data.score_trend)">{{
              data.score_trend
          }}</span>
          <i :class="'updown-icon ' + iconTrend(data.score_trend)"></i>
        </template>
      </Column>
      <Column header="Score" style="min-width: 12rem">
        <template #body="{ data }">
          <span :class="'score ' + formatScore(data.score)">{{
              data.score
          }}</span>
        </template>
      </Column>
      <Column header="Completion" style="min-width: 12rem">
        <template #body="{ data }">
          <ProgressBar :value="data.completion" :showValue="false" />
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { FilterMatchMode } from "primevue/api";

export default {
  name: "LeaderboardPublic",

  data() {
    return {
      filters: {
        team_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      },
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["compLeaderboardPublic"]),
  },
  created() {
    console.log("Leaderboard Public created");
    this.$store.dispatch("getLeaderBoardPublic");
  },
  mounted() {
    this.$store.dispatch("startUpdateCompLeaderboardPublicState");
  },
  beforeUnmount() {
    this.$store.dispatch("stopUpdateCompLeaderboardPublicState");
  },

  methods: {
    formatRank(latest, last) {
      if (latest > last) {
        return "up";
      } else if (latest < last) {
        return "down";
      } else {
        return "same";
      }
    },
    iconRank(latest, last) {
      if (latest > last) {
        return "pi pi-angle-double-up";
      } else if (latest < last) {
        return "pi pi-angle-double-down";
      } else {
        return "pi pi-angle-double-right";
      }
    },
    formatTrend(trend) {
      if (trend > 0) {
        return "up";
      } else if (trend < 0) {
        return "down";
      } else {
        return "unchanged";
      }
    },
    iconTrend(trend) {
      if (trend > 0) {
        return "pi pi-angle-double-up";
      } else if (trend < 0) {
        return "pi pi-angle-double-down";
      } else {
        return "pi pi-angle-double-right";
      }
    },

    formatScore(score) {
      if (score < 0) {
        return "negative";
      } else {
        return "positive";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  // background: #ffffff;
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 2rem;
}

::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: 0.75rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead>tr>th {
    text-align: left;
  }

  .p-datatable-tbody>tr>td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

.rank {
  background-color: #607d8b;
  color: #ffffff;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}

.up {
  background-color: #4caf50;
  color: #ffffff;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}

.down {
  background-color: #f44336;
  color: #ffffff;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}

.unchanged {
  background-color: #607d8b;
  color: #ffffff;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}

.updown-icon {
  margin-left: 0.5rem;
  font-size: 1.5rem;
  vertical-align: middle;
}

.score {
  font-weight: 500;
  font-size: 1.25rem;
}

.negative {
  color: #f44336;
}

.positive {
  color: #607d8b;
}

.team-name {
  // color: #000000;
  font-weight: 600;
  font-size: 1.25rem;
}
</style>
