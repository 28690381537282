<template>
  <div class="verify-view">
    <Navbar />
    <div class="card">
      <Verify
        :game_id="needs_verified.game_id"
        :player_id="needs_verified.player_id"
        :score="needs_verified.score"
        :signature="needs_verified.signature"
      ></Verify>
    </div>
  </div>
</template>
<script>
import Verify from "@/components/verify/Verify.vue";
import Navbar from "@/components/core/Navbar";

export default {
  name: "VerifyView",
  props: ["game_id", "player_id", "score", "signature"],
  created() {
    console.log("game_id: " + this.$route.query.game_id);
    console.log("player_id: " + this.$route.query.player_id);
    console.log("score: " + this.$route.query.score);
    console.log("signature: " + this.$route.query.signature);
    if (this.$route.query.game_id == null) {
      console.log("game_id is null");
    } else {
      this.needs_verified.game_id = this.$route.query.game_id;
    }
    if (this.$route.query.player_id == null) {
      console.log("player_id is null");
    } else {
      this.needs_verified.player_id = this.$route.query.player_id;
    }
    if (this.$route.query.score == null) {
      console.log("score is null");
    } else {
      this.needs_verified.score = this.$route.query.score;
    }
    if (this.$route.query.signature == null) {
      console.log("signature is null");
    } else {
      this.needs_verified.signature = this.$route.query.signature;
    }
  },
  components: {
    Verify,
    Navbar,
  },
  data() {
    return {
      needs_verified: {
        game_id: null,
        player_id: null,
        score: null,
        signature: null,
      },
    };
  },
};
</script>

<style scoped>
.card {
  /* background: #ffffff; */
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 2rem;
}
</style>
