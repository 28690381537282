<template>
  <Button
    type="button"
    severity="contrast"
    class="btn3 p-button-secondary"
    label="Code"
    @click="toggle"
    ><img :src="icon['code']" :alt="tool" width="20" height="20" />
    <span>Code</span></Button
  >
  <OverlayPanel ref="op">
    <!-- <h3 class="repos-header">Repos</h3> -->
    <div
      class="clone-panel"
      v-for="gameCloneCommand in gameCloneCommands"
      :key="gameCloneCommand.name"
    >
      <ArenaCloneItem :name="gameCloneCommand.name" :clone="gameCloneCommand.command" />
    </div>
    <ArenaCloneAll :clones="gameCloneCommands" />
  </OverlayPanel>
</template>
<script>
import { mapGetters } from "vuex";
import ArenaCloneItem from "@/components/arena/Arena_Clone_Item.vue";
import ArenaCloneAll from "@/components/arena/Arena_Clone_All.vue";
import git_icon from "../../../public/tools/git-white.png";

export default {
  name: "ArenaCloneMenu",
  components: {
    ArenaCloneItem,
    ArenaCloneAll,
  },
  computed: {
    ...mapGetters(["gameId", "gameCloneCommands"]),
  },
  data() {
    return {
      //dictionary of tool icons
      icon: {
        code: git_icon,
      },
    };
  },
  methods: {
    toggle(event) {
      this.$refs.op.toggle(event);
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.btn3 {
  margin: 0 0.2rem;
  width: 6.5rem;
}

.btn3 img {
  margin-right: 8px;
}

// .component-container {
//   // make this div fill the entire parent
//   height: 100%;
//   width: 100%;
//   overflow: hidden;
//   //uncomment for debug
//   // border: 1px dashed #ccc;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .container {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   // height: 25vh;
// }

.clone-panel {
  display: flex;
  flex-direction: row;
  // justify-content: space-evenly;
  align-items: center;
  // max-height: px;
  max-height: 60px;
}

.repos-header {
  background: -webkit-linear-gradient(300deg, #93f5ec 20%, #a77bf3 70%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  font-family: "Alliance1", "Alliance1HeaderFallback", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-feature-settings: unset;

  font-size: 20px !important;
  letter-spacing: -0.1em !important;
  font-weight: 900 !important;
  margin: auto;
}
</style>
