<template>
  <div class="verify">
    <Card
      ><template #title>
        <i class="pi pi-check-circle mr-4 p-text-secondary" style="font-size: 2rem"></i>
        Verify Certificate
      </template>
      <template #content>
        <div class="card">
          <div class="p-fluid grid">
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText type="text" v-model="verifier.player_id" />
                <label for="inputtext">Player ID</label>
              </span>
            </div>

            <div class="field col-4 md:col-4">
              <span class="p-float-label">
                <InputText type="text" v-model="verifier.game_id" />
                <label for="inputtext">Game ID</label>
              </span>
            </div>
            <div class="field col-4 md:col-4">
              <span class="p-float-label">
                <InputText type="text" v-model="verifier.score" />
                <label for="inputtext">Score</label>
              </span>
            </div>
            <div class="field col-12 md:col-12">
              <span class="p-float-label">
                <InputText type="text" v-model="verifier.signature" />
                <label for="inputtext">Signature</label>
              </span>
            </div>
            <div class="field col-12 md:col-4">
              <Button label="Verify" @click="verifyCertificate(verifier)" />
            </div>
            <div class="field col-12 md:col-4">
              <div class="verified-icon" v-if="certificateVerified === null">
                <!-- HTML for when the property is null -->
                <i
                  class="pi pi-question mr-4 p-text-secondary unknown"
                  style="font-size: 4rem"
                ></i>
              </div>
              <div class="verified-icon" v-else-if="certificateVerified === true">
                <!-- HTML for when the property is true -->
                <i
                  class="pi pi-check-circle mr-4 p-text-secondary verified"
                  style="font-size: 4rem"
                ></i>
              </div>
              <div class="verified-icon" v-else>
                <!-- HTML for when the property is false -->
                <i
                  class="pi pi-times mr-4 p-text-secondary unverified"
                  style="font-size: 4rem"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Verify",
  props: ["game_id", "player_id", "score", "signature"],
  components: {},
  data() {
    return {
      verifier: {
        game_id: null,
        player_id: null,
        score: null,
        signature: null,
      },
    };
  },
  computed: {
    ...mapGetters(["certificateVerified"]),
  },
  methods: {
    ...mapActions(["verifyCertificate"]),
  },
  created() {
    console.log("Launch created");
    this.$store.dispatch("resetVerified");
    console.log("game_id: " + this.$route.query.game_id);
    console.log("player_id: " + this.$route.query.player_id);
    console.log("score: " + this.$route.query.score);
    console.log("signature: " + this.$route.query.signature);
    if (this.$route.query.game_id == null) {
      console.log("game_id is null");
    } else {
      this.verifier.game_id = this.$route.query.game_id;
    }
    if (this.$route.query.player_id == null) {
      console.log("player_id is null");
    } else {
      this.verifier.player_id = this.$route.query.player_id;
    }
    if (this.$route.query.score == null) {
      console.log("score is null");
    } else {
      this.verifier.score = this.$route.query.score;
    }
    if (this.$route.query.signature == null) {
      console.log("signature is null");
    } else {
      this.verifier.signature = this.$route.query.signature;
    }
  },
};
</script>
<style lang="scss" scoped>
.verified {
  color: green;
}
.unverified {
  color: red;
}
.unknown {
  color: grey;
}
.verified-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
