<template>
  <nav id="navbar" class="gamedays-navbar">
    <Toolbar v-if="isLargeScreen">
      <template #start>
        <div class="sm:center">
          <Logo class="m-1"></Logo>
        </div>
      </template>

      <template #center>
        <div class="flex flex-wrap align-items-center">
          <Button
            icon="pi pi-fw pi-home"
            label="Home"
            @click="navigate('/')"
            class="m-1 nav-button"
          />
          <Button
            icon="pi pi-fw pi-th-large"
            label="Games"
            @click="navigate('/launch')"
            class="m-1 nav-button"
          />
          <Button
            icon="pi pi-fw pi-th-large"
            label="Comps"
            @click="navigate('/comps')"
            class="m-1 nav-button sm:flex-shrink-1"
          />
          <Button
            icon="pi pi-fw pi-comments"
            label="FAQ"
            @click="navigate('/faq')"
            class="m-1 nav-button sm:flex-shrink-1"
          />
          <Button
            icon="pi pi-fw pi-shopping-cart"
            label="Shop"
            @click="navigate('/inventory')"
            class="m-1 nav-button"
          />
          <!-- <Button
            icon="pi pi-fw pi-tags"
            label="Purchases"
            @click="navigate('/purchases')"
            class="m-1 nav-button"
          /> -->
          <Button
            icon="pi pi-fw pi-check-circle"
            label="Verify"
            @click="navigate('/verify')"
            class="m-1 nav-button"
          />

          <Button
            icon="pi pi-fw pi-shopping-cart"
            label="Cart"
            @click="navigate('/cart')"
            class="m-1 nav-button"
            :badge="cartQuantity"
            :severity="cartSeverity"
          />
        </div>
      </template>

      <template #end>
        <div class="flex align-items-center">
          <!-- <span v-if="oidcUser" class="mr-2">{{ oidcUser.name }}</span> -->

          <Button
            v-if="oidcUser"
            label="Logout"
            @click="signOutOidc"
            class="m-1 nav-button"
            icon="pi pi-fw pi-sign-out"
            severity="danger"
          />

          <Button
            v-else
            label="Login"
            @click="authenticateOidc"
            class="m-1 nav-button"
            icon="pi pi-fw pi-sign-in"
            severity="success"
          />
          <span v-if="oidcUser" class="ml-2 logged-in-user">{{ oidcUser.name }}</span>
        </div>
      </template>
    </Toolbar>
    <Toolbar v-else>
      <template #start>
        <div class="sm:center">
          <Logo class="m-1"></Logo>
        </div>
      </template>
      <template #center>
        <div class="flex flex-wrap align-items-center">
          <Button
            icon="pi pi-fw pi-home"
            label="Home"
            @click="navigate('/')"
            class="m-1 nav-button"
          />
          <Button
            icon="pi pi-fw pi-th-large"
            label="Games"
            @click="navigate('/launch')"
            class="m-1 nav-button"
          />
          <Button
            icon="pi pi-fw pi-th-large"
            label="Comps"
            @click="navigate('/comps')"
            class="m-1 nav-button sm:flex-shrink-1"
          />
          <Button
            icon="pi pi-fw pi-comments"
            label="FAQ"
            @click="navigate('/faq')"
            class="m-1 nav-button sm:flex-shrink-1"
          />
          <Button
            icon="pi pi-fw pi-shopping-cart"
            label="Shop"
            @click="navigate('/inventory')"
            class="m-1 nav-button"
          />
          <!-- <Button
            icon="pi pi-fw pi-tags"
            label="Purchases"
            @click="navigate('/purchases')"
            class="m-1 nav-button"
          /> -->
          <Button
            icon="pi pi-fw pi-check-circle"
            label="Verify"
            @click="navigate('/verify')"
            class="m-1 nav-button"
          />

          <Button
            icon="pi pi-fw pi-shopping-cart"
            label="Cart"
            @click="navigate('/cart')"
            class="m-1 nav-button"
            :badge="cartQuantity"
            :severity="cartSeverity"
          />
          <Button
            v-if="oidcUser"
            label="Logout"
            @click="signOutOidc"
            class="m-1 nav-button"
            icon="pi pi-fw pi-sign-out"
            severity="danger"
          />

          <Button
            v-else
            label="Login"
            @click="authenticateOidc"
            class="m-1 nav-button"
            icon="pi pi-fw pi-sign-in"
            severity="success"
          />
          <span v-if="oidcUser" class="ml-2 logged-in-user">{{ oidcUser.name }}</span>
        </div>
      </template>
    </Toolbar>
  </nav>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import router from "@/router";
import Logo from "@/components/core/Logo.vue";

export default {
  name: "Navbar",
  components: {
    Logo,
  },
  data() {
    return {
      isLargeScreen: false,
    };
  },
  computed: {
    ...mapGetters(["cartQuantity", "oidcUser"]),
    cartSeverity() {
      if (this.cartQuantity > 0) {
        return "warning";
      } else {
        return "";
      }
    },
    initials() {
      return this.oidcUser.name
        .split(" ")
        .map((n) => n[0])
        .join("")
        .toUpperCase();
    },
  },
  methods: {
    ...mapActions(["signOutOidc", "authenticateOidc"]),
    navigate(target) {
      router.push({ path: target });
    },
    checkScreenSize() {
      this.isLargeScreen = window.matchMedia("(min-width: 768px)").matches;
      if (this.isLargeScreen) {
        console.log("large screen");
      } else {
        console.log("small screen");
      }
    },
  },
  created() {
    this.$store.dispatch("getCartItems");
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
};
</script>

<style scoped lang="scss">
.gamedays-navbar {
  height: calc(100% - 780px);
  background-color: #0d1117;
  border-radius: 20px;
  align-items: center;
  top: 0;
  z-index: 999999;
}

.p-toolbar {
  padding: 0.3rem;
}

.below {
  position: sticky;
  z-index: 1000;
  border-color: greenyellow;
  background-color: #0d1117;
  border-style: dashed;
  height: 10px;
}

.progress-normal {
  height: 5px;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin: 0;
  padding: 0;
  position: relative;
}
.progress-fixed {
  position: fixed;
  top: 100;
  padding-top: 100px;
  z-index: 999999;
}

.logged-in-user {
  font-size: 0.9rem;
  max-width: 12rem;
  white-space: nowrap;
}

.nav-button {
  width: 6.5rem;
}

@media only screen and (max-width: 960px) {
  .nav-button {
    width: 6rem;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 576px) {
  .sm\:center {
    // display: none !important;
    display: flex;
    justify-content: center;
    min-width: 95vw;
    // border-style: dashed;
    // border-color: greenyellow;
  }
}
</style>
