<template>
  <nav class="crumbs gamedays-navbar">
    <Toolbar>
      <template #start>
        <LogoPrompt class="m-1"></LogoPrompt>
      </template>

      <template #center>
        <div class="flex flex-wrap align-items-center">
          <ArenaCloneMenu />
          <ArenaToolsButton2 text="Jenkins" tool="jenkins" :gameId="gameId" />
          <ArenaToolsButton2 text="Gitea" tool="gitea" :gameId="gameId" />
          <ArenaToolsButton2 text="Nexus" tool="nexus" :gameId="gameId" />
          <ArenaToolsButton2 text="Grafana" tool="grafana" :gameId="gameId" />
          <ArenaToolsButton2 text="Jaeger" tool="jaeger" :gameId="gameId" />
          <ArenaToolsButton2 text="Terminal" tool="terminal" :gameId="gameId" />
          <ArenaToolsButton2 text="Test" tool="test" :gameId="gameId" />
          <ArenaToolsButton2 text="Prod" tool="prod" :gameId="gameId" />
          <ArenaToolsButton2 text="Dev" tool="dev" :gameId="gameId" />
        </div>
      </template>

      <!-- <template #end>
        <div class="flex align-items-center">
          <ArenaCloneMenu />
        </div>
      </template> -->
    </Toolbar>
  </nav>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import router from "@/router";
import LogoPrompt from "@/components/core/LogoPrompt.vue";
// import ArenaToolsButton from "@/components/arena/Arena_ToolsButton.vue";
import ArenaToolsButton2 from "@/components/arena/Arena_ToolsButton2.vue";
import ArenaCloneMenu from "@/components/arena/Arena_CloneMenu.vue";

export default {
  name: "ArenaNavbar",
  components: {
    LogoPrompt,
    // ArenaToolsButton,
    ArenaToolsButton2,
    ArenaCloneMenu,
  },
  computed: {
    ...mapGetters(["cartQuantity", "oidcUser", "gameId"]),
    cartSeverity() {
      if (this.cartQuantity > 0) {
        return "warning";
      } else {
        return "";
      }
    },
    initials() {
      return this.oidcUser.name
        .split(" ")
        .map((n) => n[0])
        .join("")
        .toUpperCase();
    },
  },
  methods: {
    ...mapActions(["signOutOidc", "authenticateOidc"]),
    navigate(target) {
      router.push({ path: target });
    },
  },
  created() {
    this.$store.dispatch("getCartItems");
  },
};
</script>

<style scoped lang="scss">
.gamedays-navbar {
  height: calc(100% - 780px);
  background: linear-gradient(
    180deg,
    rgba(85, 48, 152, 0.3) 0%,
    rgba(83, 48, 152, 0) 100%
  );
  background-color: #0d1117;
  border-radius: 20px;
  align-items: center;
}

.logged-in-user {
  font-size: 0.9rem;
  max-width: 12rem;
  white-space: nowrap;
}

.nav-button {
  width: 6.5rem;
}

@media only screen and (max-width: 960px) {
  .nav-button {
    width: 6rem;
    font-size: 0.8rem;
  }
}
</style>
