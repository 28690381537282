<template>
  <div class="block component-container">
    <!-- <Chip
      :label="gameSelectedContact"
      :image="require('@/assets/jenkins256.png')"
      class="contact-chip"
    /> -->
    <div class="chat-title">
      <ArenaAvatar :contact="gameSelectedContact"></ArenaAvatar>
      <h3 class="contact-chip">{{ gameSelectedContact }}</h3>
    </div>
    <div class="chat-main">
      <ScrollPanel header="Chat" class="custombar1">
        <p v-if="!gameContactMessages.length">No recent messages found</p>
        <ul>
          <li
            v-for="gameContactMessage in gameContactMessages"
            :key="gameContactMessage.message_id"
          >
            <ArenaChatItem :messageItem="gameContactMessage" />
          </li>
          <br />
        </ul>
      </ScrollPanel>

      <div class="p-inputgroup">
        <InputText
          placeholder="Type a new message"
          v-model="newMessage.text"
          @keyup.enter="
            sendGameChatMessage(newMessage);
            clearNewMessage();
          "
        />
        <Button
          icon="pi pi-send"
          class="primary-500"
          @click="
            sendGameChatMessage(newMessage);
            clearNewMessage();
          "
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ArenaChatItem from "./Arena_Chat_Item";
import ArenaAvatar from "./Arena_Avatar";

export default {
  name: "ArenaChat",
  components: {
    ArenaChatItem,
    ArenaAvatar,
  },
  computed: {
    ...mapGetters(["gameContactMessages", "gameSelectedContact"]),
  },
  methods: {
    ...mapActions(["sendGameChatMessage"]),
    clearNewMessage() {
      this.newMessage = {
        text: "",
      };
    },
  },
  data() {
    return {
      newMessage: {
        text: null,
      },
    };
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.contact-chip {
  font-size: 1.1rem;
  font-weight: 700;
}

.component-container {
  // make this div fill the entire parent
  height: 100%;
  //uncomment for debug
  // border: 1px dashed #29b71a;
}

// .chats-header {
//   background: -webkit-linear-gradient(300deg, #93f5ec 20%, #a77bf3 70%);
//   -webkit-background-clip: text;
//   background-clip: text;
//   -webkit-text-fill-color: transparent;
//   -webkit-box-decoration-break: clone;
//   font-family: "Alliance1", "Alliance1HeaderFallback", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
//   font-feature-settings: unset;

//   font-size: 20px !important;
//   letter-spacing: -0.1em !important;
//   font-weight: 900 !important;
//   margin: auto;

// }

.chat-title {
  display: flex;
  align-items: center;
  // padding: 1rem;
  // border-bottom: 1px solid var(--surface-border);
}

.chat-main {
  height: calc(100% - 4rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

// .chat-box {
//   width: 100%;
//   height: 100%;
//   margin-bottom: 1rem;
// }

ul {
  padding: 1rem;
  margin: 0;
  list-style-type: none;
}

ul li {
  padding: 0.1rem 0;
  display: flex;
  align-items: flex-start;
}

ul li img {
  width: 36px;
}

ul li div {
  padding: 0.1rem 1rem;
  margin: 0.25rem 0;
  border-radius: 3px;
}

// https://www.primefaces.org/primevue/scrollpanel
::v-deep(.p-scrollpanel) {
  p {
    padding: 0.5rem;
    line-height: 1.5;
    margin: 0;
  }

  &.custombar1 {
    width: 100%;
    height: 82%;
    margin-bottom: 1rem;

    .p-scrollpanel-wrapper {
      border-right: 9px solid var(--surface-ground);
    }

    .p-scrollpanel-bar {
      background-color: var(--primary-color);
      opacity: 1;
      transition: background-color 0.2s;

      &:hover {
        background-color: #007ad9;
      }
    }
  }

  &.custombar2 {
    .p-scrollpanel-wrapper {
      border-right: 9px solid var(--surface-border);
      border-bottom: 9px solid var(--surface-border);
    }

    .p-scrollpanel-bar {
      background-color: var(--surface-ground);
      border-radius: 0;
      opacity: 1;
      transition: background-color 0.2s;
    }
  }
}
</style>
