<template>
  <div class="splash">
    <img alt="logo" src="@/assets/earphones.png" />
    <div class="logo-game">game</div>
    <div class="logo-days">days</div>
  </div>
</template>
<script></script>
<style scoped lang="scss">
.splash {
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(41, 40, 40, 1) 57%);
  color: rgb(255, 255, 255);
  font-weight: 900;
  font-size: 30mm;
  font-family: fantasy;
  position: relative;
  text-align: center;
  color: white;
}
.logo-game {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.logo-days {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
