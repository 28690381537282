<template>
  <!-- <div class="unread-messages-wrapper" @click="launchTool(gameId, tool)">
        <button class="button unread-messages-inner">
            <img :src="icon" :alt="tool" width="20" height="20" />
            <span>{{ text }}</span>
        </button>

    </div>
    <button class="btn">
        <img :src="icon" :alt="tool" width="20" height="20" />
        <span>{{ text }}</span>
    </button> -->
  <button class="btn2" @click="launchTool(gameId, tool)">
    <img :src="icon" :alt="tool" width="20" height="20" />
    <span>{{ text }}</span>
  </button>
</template>
<script>
import jenkins_icon from "../../../public/tools/jenkins.svg";
import gitea_icon from "../../../public/tools/gitea.svg";
import nexus_icon from "../../../public/tools/nexus-white.png";
import grafana_icon from "../../../public/tools/grafana.svg";
import jaeger_icon from "../../../public/tools/jaeger.svg";
import terminal_icon from "../../../public/tools/terminal.png";
import test_icon from "../../../public/tools/test.drawio.png";
import prod_icon from "../../../public/tools/prod.drawio.png";
import dev_icon from "../../../public/tools/dev.drawio.png";

export default {
    name: "ArenaToolsButton",
    props: {

        text: String,
        gameId: String,
        tool: String,
    },
    methods: {
        launchTool(_gameId, _tool) {
            // lookup the prefix for the tool
            var prefix = this.prefixes[_tool];
            // if there is a postfix, add it to the url

            var postfix = this.postfixes[_tool];
            var url = `https://${prefix}.${_gameId}.gamedays.aw125.co.uk/${postfix}`;
            window.open(url, "_blank");
        },
    },
    computed: {
        icon() {
            // check if tool is in the icons dictionary and return the icon. if not, return a default icon
            return this.icons[this.tool] ? this.icons[this.tool] : this.icons["jenkins"];
        },

    },
    data() {
        return {
            //dictionary of tool icons
            icons: {
                jenkins: jenkins_icon,
                gitea: gitea_icon,
                nexus: nexus_icon,
                grafana: grafana_icon,
                jaeger: jaeger_icon,
                terminal: terminal_icon,
                test: test_icon,
                prod: prod_icon,
                dev: dev_icon,
            },
            prefixes: {
                jenkins: "jenkins",
                gitea: "gitea",
                nexus: "nexus3",
                grafana: "grafana",
                jaeger: "jaeger",
                terminal: "guacamole",
                test: "test1-entry-bash-as-a-service",
                prod: "entry-bash-as-a-service",
                dev: "dev"
            },
            postfixes: {
                jenkins: "",
                gitea: "user/oauth2/gamedays",
                nexus: "",
                grafana: "login/generic_oauth",
                jaeger: "",
                terminal: "",
                test: "",
                prod: "",
                dev: ""
            }
        };
    },
};
</script>

<style scoped>
.button {
  display: flex;
  align-items: center;
  justify-content: left;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); */
  /* padding: 4px 4px; */
  width: 98%;
  height: 98%;
  /* margin: 4px; */
}

.button img {
  margin-right: 8px;
}

.button span {
  font-size: 16px;
}

.unread-messages-wrapper {
  /* padding: 1px !important; */
  background: -webkit-linear-gradient(300deg, #93f5ec 20%, #a77bf3 70%);
  /* border-radius: 5px */
  width: 100px;
  border-radius: 4px;
  /* padding: 4px 4px; */
  margin: 4px;
}

.unread-messages-wrapper:hover {
  box-shadow: 0 0 10px #fff;
}

.unread-messages-inner {
  /* padding: 1px; */
  background: var(--surface-card);
  /* border-radius: 5px */
  border: 1px;
  border-color: none;
}

.btn {
  /* padding: 20px 60px; */
  border: none;
  outline: none;
  position: relative;
  border-radius: 5px;
  background: linear-gradient(to right, #00ffa3, #dc1fff);
  cursor: pointer;
}

.btn::before {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  background-color: black;
  border-radius: 4px;
}

.btn2 {
  background: linear-gradient(var(--surface-card), var(--surface-card)) padding-box,
    linear-gradient(300deg, #93f5ec 20%, #a77bf3 70%) border-box;
  /* color: #313149; */
  /* padding: 20px 70px; */
  border: 1px solid transparent;
  border-radius: 10px;
  /* display: inline-block; */
  font-size: 14px;
  color: var(--text-primary);
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 2px;
  padding: 5px;
}

.btn2 img {
  margin-right: 8px;
}

.btn2:hover {
  box-shadow: 0 0 10px #fff;
}
</style>
