<template>
    <div class="container">
        <div class="clone-item">
            <label for="clone">{{ name }}</label>
            <InputText id="clone" type="text" v-model="cloneCommand" />
            <Button icon="pi pi-copy" @click="copyToClipboard(cloneCommand)" v-tooltip.right="'Copy to clipboard'" />
        </div>
    </div>
</template>
<script>

export default {
    name: "ArenaCloneItem",
    props: {

        name: String,
        clone: String,
    },
    methods: {
        copyToClipboard(_text) {
            const el = document.createElement("textarea");
            el.value = _text;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
        }
    },
    computed: {
        icon() {
            // check if tool is in the icons dictionary and return the icon. if not, return a default icon
            return this.icons[this.tool] ? this.icons[this.tool] : this.icons["jenkins"];
        },
        cloneCommand() {
            return this.clone;
        }
    },
};
</script>

<style scoped>
.container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    margin: 10px;
    padding: 2px;
}

.item {
    padding: 2px;
    margin: 1px;
}


label {
    display: block;
}
</style>