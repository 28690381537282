// import axios from 'axios';
// import { apiSettings } from '../../../config/api'
// import router from '../../../router'
const state = {
  _agreements: {
    acceptCookies: false,
    acceptTerms: false
  }
}
const mutations = {
  UPDATE_AGREEMENTS(state, cookies, terms) {
    state._agreements.acceptCookies = cookies;
    state._agreements.acceptTerms = terms;
    localStorage.setItem('agreements', JSON.stringify(state._agreements));
  }
}
const actions = {
  setAcceptCookies({ commit, state }, accepted) {
    //TODO send this to the api  so we have a record of it
    console.log("setAcceptCookies", accepted);
    commit('UPDATE_AGREEMENTS', accepted, state._agreements.acceptTerms);
  },
  setAcceptTerms({ commit, state }, accepted) {
    //TODO send this to the api so we have a record of it
    commit('UPDATE_AGREEMENTS', accepted, state._agreements.acceptCookies);
  },
  getAgreements({ commit }) {
    if (localStorage.getItem('agreements')) {
      let agreements = JSON.parse(localStorage.getItem('agreements'));
      commit('UPDATE_AGREEMENTS', agreements.acceptCookies, agreements.acceptTerms)
    } else {
      // Set the defaults to false
      commit('UPDATE_AGREEMENTS', false, false)
    }
  },

}
const getters = {
  acceptCookies: state => state._agreements.acceptCookies,
  acceptTerms: state => state._agreements.acceptTerms,
}
const agreementsModule = {
  state,
  mutations,
  actions,
  getters
}

export default agreementsModule;