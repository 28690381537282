<template>
  <div class="component-container">
    <div :class="chatStyleWrapper(messages)">
      <div :class="chatStyleInner(messages)">
        <!-- <Avatar :image="require('@/assets/jenkins256.png')" size="large" /> -->
        <ArenaAvatar :contact="contact" />
        <div class="product-list-detail">
          <div class="product-name">{{ contact }}</div>
          <div class="product-description">{{ job_title }}</div>

          <i :class="statusIcon(status)"></i
          ><span class="product-category">{{ status }}</span>
          <div>{{ chatPreview(messages) }}</div>
        </div>
        <div class="product-list-action">
          <Button
            icon="pi pi-comment"
            label="Open"
            :badge="unreadMessages(messages)"
            badgeClass="p-badge-info"
            @click="openChat(contact)"
          ></Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import ArenaAvatar from "./Arena_Avatar.vue";

export default {
  name: "ArenaChatsItem",
  components: {
    ArenaAvatar,
  },
  props: {
    contact: String,
    job_title: String,
    status: String,
    messages: Array,
  },
  // computed: {
  //     ...mapGetters(["gameChats"]),
  // },
  methods: {
    ...mapActions(["openChat"]),
    statusIcon(status) {
      if (status === "Online") {
        return "pi pi-circle-on status-online";
      } else if (status === "Away") {
        return "pi pi-circle-on status-away";
      } else if (status === "Busy") {
        return "pi pi-circle-on status-busy";
      } else if (status === "Offline") {
        return "pi pi-circle-on status-offline";
      } else {
        return "pi pi-circle-off status-unknown";
      }
    },
    unreadMessages(messages) {
      // count all messages.read === false. If all are read, return 0
      var unread = messages.filter((message) => message.read === false).length;
      if (unread > 0) {
        return unread.toString();
      } else {
        return "0";
      }
    },
    hasUnreadMessages(messages) {
      return this.unreadMessages(messages) > 0;
    },
    chatStyleWrapper(messages) {
      if (this.hasUnreadMessages(messages)) {
        return "product-list-item unread-messages-wrapper";
      } else {
        return "product-list-item";
      }
    },
    chatStyleInner(messages) {
      if (this.hasUnreadMessages(messages)) {
        return "product-list-item unread-messages-inner";
      } else {
        return "product-list-item";
      }
    },
    chatPreview(messages) {
      var lastMessage = messages[messages.length - 1];
      if (lastMessage) {
        //return the last 20 characters of the message or the whole message if it's less than 20 characters
        return (
          lastMessage.text.substring(0, 20) + (lastMessage.text.length > 20 ? "..." : "")
        );
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      placeholder: "todo",
    };
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.component-container {
  // make this div fill the entire parent
  // height: 100%;
  // width: 100%;
  // overflow: scroll;
  // // display: flex;
  // // flex-direction: column;
  // // justify-content: space-between;
  // // align-items: stretch;
  // // background-color: #f1f1f1;
  // border: 1px dashed #ccc;
  margin-bottom: 2px;
}

.chats-header {
  font-weight: 600;
}

// .unread-messages {
//     background-color: #f6f0f0;
// }

.unread-messages-wrapper {
  padding: 1px !important;
  background: -webkit-linear-gradient(300deg, #93f5ec 20%, #a77bf3 70%);
  border-radius: 5px;
}

.unread-messages-wrapper:hover {
  box-shadow: 0 0 10px #fff;
}

.unread-messages-inner {
  padding: 20px;
  background: var(--surface-card);
  border-radius: 5px;
}

.status-away {
  color: yellow;
}

.status-online {
  color: green;
}

.status-busy {
  color: red;
}

.status-offline {
  color: gray;
}

.status-unknown {
  color: black;
}

// .card {
//     background: #ffffff;
//     padding: 2rem;
//     box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
//     border-radius: 4px;
//     margin-bottom: 2rem;
// }

// .p-dropdown {
//     width: 14rem;
//     font-weight: normal;
// }

.product-name {
  font-size: 1.1rem;
  font-weight: 700;
}

.product-description {
  margin: 0 0 1rem 0;
}

// .product-category-icon {
//     vertical-align: middle;
//     margin-right: .5rem;
// }

.product-category {
  font-weight: 600;
  vertical-align: middle;
}

::v-deep(.product-list-item) {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;

  img {
    width: 50px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 2rem;
  }

  .product-list-detail {
    flex: 1 1 0;
  }

  .p-rating {
    margin: 0 0 0.5rem 0;
  }

  .product-price {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    align-self: flex-end;
  }

  .product-list-action {
    display: flex;
    flex-direction: column;
  }

  .p-button {
    margin-bottom: 0.5rem;
  }
}

::v-deep(.product-grid-item) {
  margin: 0.5rem;
  border: 1px solid var(--surface-border);

  .product-grid-item-top,
  .product-grid-item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  img {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 2rem 0;
  }

  .product-grid-item-content {
    text-align: center;
  }

  .product-price {
    font-size: 1.5rem;
    font-weight: 600;
  }
}

@media screen and (max-width: 576px) {
  .product-list-item {
    flex-direction: column;
    align-items: center;

    img {
      margin: 2rem 0;
    }

    .product-list-detail {
      text-align: center;
    }

    .product-price {
      align-self: center;
    }

    .product-list-action {
      display: flex;
      flex-direction: column;
    }

    .product-list-action {
      margin-top: 2rem;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}

// https://www.primefaces.org/primevue/scrollpanel
::v-deep(.p-scrollpanel) {
  p {
    padding: 0.5rem;
    line-height: 1.5;
    margin: 0;
  }

  &.custombar1 {
    .p-scrollpanel-wrapper {
      border-right: 9px solid var(--surface-ground);
    }

    .p-scrollpanel-bar {
      background-color: var(--primary-color);
      opacity: 1;
      transition: background-color 0.2s;

      &:hover {
        background-color: #007ad9;
      }
    }
  }

  &.custombar2 {
    .p-scrollpanel-wrapper {
      border-right: 9px solid var(--surface-border);
      border-bottom: 9px solid var(--surface-border);
    }

    .p-scrollpanel-bar {
      background-color: var(--surface-ground);
      border-radius: 0;
      opacity: 1;
      transition: background-color 0.2s;
    }
  }
}
</style>
