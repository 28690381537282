<template>
  <div class="purchases">
    <Navbar />
    <div class="card">
      <div class="col-6 cart-header" style="text-align: left">
        Used {{ singleplayerGamesUsed }}/{{ singleplayerGamesOwned }} single player games
        {{ multiplayerGamesUsed }}/{{ multiplayerGamesOwned }} multi player games
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Navbar from "@/components/core/Navbar";

export default {
  name: "Purchases",
  props: ["session_id"],
  components: {
    Navbar,
  },
  data() {
    return {
      layout: "list",
      sortKey: null,
      sortOrder: null,
      sortField: null,
      sortOptions: [
        { label: "Price High to Low", value: "!price" },
        { label: "Price Low to High", value: "price" },
      ],
      visible: false,
    };
  },
  computed: {
    ...mapGetters([
      "singleplayerGamesOwned",
      "multiplayerGamesOwned",
      "singleplayerGamesUsed",
      "multiplayerGamesUsed",
      "stripeTracker",
    ]),
  },
  beforeCreate() {
    console.log("purchases beforeCreate");
    console.log("session_id: " + this.session_id);

    // check if session_id prop is not null. If so dispatch getStripeTracker
    if (this.session_id != null) {
      console.log("session_id is not null");
      this.$store.dispatch("getStripeTracker", this.session_id);
    }

    // //check if game_id is null from vuex and if so, set it to the prop
    // if (this.$store.getters.gameId == null) {
    //   console.log("game_id is null using url prop");
    //   this.$store.dispatch("setGameId", this.game_id);
    // }
    // this.$store.dispatch("getGameState");
    // this.$store.dispatch("getGameChats");
    // this.$store.dispatch("getGameCloneCommands");
  },
  created() {
    console.log("purchases created");
    this.$store.dispatch("getPurchases");
  },
  methods: {
    ...mapActions(["getStripeTracker"]),
  },
};
</script>
<style lang="scss" scoped>
.card {
  //background: #ffffff;
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 2rem;
}

.game-create-dialog {
  width: 50rem;
  height: 50rem;
}

// .game-new {
//   margin-bottom: 1rem;
//   // border-bottom: 1px solid var(--surface-border);
// }

// .p-dropdown {
//   width: 14rem;
//   font-weight: normal;
// }

.product-name {
  font-size: 1.5rem;
  font-weight: 700;
}

.product-description {
  margin: 0 0 1rem 0;
}

.product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.product-category {
  font-weight: 600;
  vertical-align: middle;
}

::v-deep(.product-list-item) {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;

  img {
    width: 50px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 2rem;
  }

  .product-list-detail {
    flex: 1 1 0;
  }

  // .p-rating {
  //   margin: 0 0 0.5rem 0;
  // }

  // .product-price {
  //   font-size: 1.5rem;
  //   font-weight: 600;
  //   margin-bottom: 0.5rem;
  //   align-self: flex-end;
  // }

  .product-list-action {
    display: flex;
    flex-direction: column;
  }

  .p-button {
    margin-bottom: 0.5rem;
  }
}

::v-deep(.product-grid-item) {
  margin: 0.5rem;
  border: 1px solid var(--surface-border);

  .product-grid-item-top,
  .product-grid-item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  img {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 2rem 0;
  }

  .product-grid-item-content {
    text-align: center;
  }

  // .product-price {
  //   font-size: 1.5rem;
  //   font-weight: 600;
  // }
}

@media screen and (max-width: 576px) {
  .product-list-item {
    flex-direction: column;
    align-items: center;

    img {
      margin: 2rem 0;
    }

    .product-list-detail {
      text-align: center;
    }

    // .product-price {
    //   align-self: center;
    // }

    .product-list-action {
      display: flex;
      flex-direction: column;
    }

    .product-list-action {
      margin-top: 2rem;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}
</style>
