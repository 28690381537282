import axios from 'axios';
import { apiSettings } from '@/config/api'
const state = {
  gameItems: [],
  gamesInterval: null,

}
const mutations = {
  UPDATE_GAME_ITEMS(state, payload) {
    state.gameItems = payload;
  },
  UPDATE_GAMES_STATE_INTERVAL(state, interval) {
    state.gameInterval = interval;
  }
}
const actions = {
  getGameItems({ commit, dispatch }) {
    console.log(`${apiSettings.gamesdays_core_game_api}`)
    axios.get(`${apiSettings.gamesdays_core_game_api}`).then((response) => {
      commit('UPDATE_GAME_ITEMS', response.data)

    }).catch((error) => {
      console.log(error)
      dispatch('handleError', "Failed to get games. Please try again.", { root: true })
    });
  },
  createGame({ rootState, dispatch }, payload) {
    const adminId = rootState.oidcStore.user
    var now = new Date();
    console.log(payload)
    var players = []
    if (payload.player1 !== null) {
      players.push(payload.player1)
    }
    if (payload.player2 !== null) {
      players.push(payload.player2)
    }
    if (payload.player3 !== null) {
      players.push(payload.player3)
    }
    if (payload.player4 !== null) {
      players.push(payload.player4)
    }
    var game = {
      "admin_ids": [adminId.email],
      "team_name": payload.teamName,
      "status": "created",
      "created_at": now.toISOString(),
      "completion": 0,
      "player_ids": players,
      "multiplayer": payload.multiplayer,
      "score": 0,
      "score_trend": 0,
      "online": 0,
      "rps": 0,
      "eps": 0,
      "public": payload.public,
      "owner": adminId.email,
    }
    console.log(game)
    axios.post(`${apiSettings.gamesdays_core_game_api}`, game).then((response) => {
      console.log(response)
      dispatch('getGameItems')
      dispatch('getPurchases') // this is in purchases module
    }).catch((error) => {
      console.log(error)
      dispatch('handleError', "Failed to create game. Please try again.", { root: true })
    });
  },
  startGame({ dispatch }, game_id) {
    var now = new Date();
    console.log(game_id)
    var patch = {
      "status": "starting",
      "started_at": now.toISOString()
    }
    axios.patch(`${apiSettings.gamesdays_core_game_api}/${game_id}`, patch).then((response) => {
      console.log(response)
      // commit('UPDATE_CART_ITEMS', [])
      dispatch('getGameItems')
    }).catch((error) => {
      console.log(error)
      dispatch('handleError', "Failed to start game. Please try again.", { root: true })
    });
  },
  // every 5s dispatch actions which updates the game progress and score
  startUpdateGamesState({ dispatch, commit }) {
    console.log("start updating game state")
    if (state.gameInterval === null) {
      var stateInterval = setInterval(() => {

        dispatch('getGameItems')

      }, 30000);
      commit('UPDATE_GAMES_STATE_INTERVAL', stateInterval)
    } else {
      console.log("game state update already running")
    }
  },
  stopUpdateGamesState({ state, commit }) {
    console.log("stop updating game state")
    clearInterval(state.gamesInterval)
    commit('UPDATE_GAMES_STATE_INTERVAL', null)
  },
}
const getters = {
  gameItems: state => state.gameItems,
  // singleplayerGames: state => {
  //   const gameItems = state.gameItems
  //   console.log(gameItems)
  //   var singleplayerGames = 0
  //   gameItems.map((gameItem) => {
  //     singleplayerGames += gameItem.singleplayer_games
  //   });
  //   return singleplayerGames
  // },
  // multiplayerGames: state => {
  //   const gameItems = state.gameItems
  //   console.log(gameItems)
  //   var multiplayerGames = 0
  //   gameItems.map((gameItem) => {
  //     multiplayerGames += gameItem.multiplayer_games
  //   });
  //   return multiplayerGames
  // },
}
const gamesModule = {
  state,
  mutations,
  actions,
  getters
}

export default gamesModule;