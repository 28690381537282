import { createStore } from 'vuex'
import product from './modules/product';
import cart from './modules/cart';
import purchases from './modules/purchases';
import games from './modules/games'
import arena from './modules/arena'
import names from './modules/names'
import agreements from './modules/agreements'
import comps from './modules/comps'
import certificate from './modules/certificate';
import messages from './modules/messages';
import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import { oidcSettings } from '../config/oidc'
import axios from 'axios';


export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    product,
    cart,
    purchases,
    games,
    arena,
    names,
    agreements,
    comps,
    certificate,
    messages,
    oidcStore: vuexOidcCreateStoreModule(oidcSettings, {
      dispatchEventsOnWindow: true
    },
      // Optional OIDC event listeners
      {
        userLoaded: (user) => {
          console.log('OIDC user is loaded:', user),
            axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}` // for all requests
        },
        userUnloaded: () => console.log('OIDC user is unloaded'),
        accessTokenExpiring: () => console.log('Access token will expire'),
        accessTokenExpired: () => console.log('Access token did expire'),
        silentRenewError: () => console.log('OIDC user is unloaded'),
        userSignedOut: () => console.log('OIDC user is signed out'),
        oidcError: (payload) => {
          console.log(`An error occurred at ${payload.context}:`, payload.error);
          // We also log this in the SystemMessages component via window event listener
        },
        automaticSilentRenewError: (payload) => {
          console.log('Automatic silent renew failed.', payload.error)
          // We also log this in the SystemMessages component via window event listener

        }
      })
  }
})
