import axios from 'axios';
import { apiSettings } from '@/config/api'
const state = {
  singleplayerGamesOwned: 0,
  multiplayerGamesOwned: 0,
  singleplayerGamesUsed: 0,
  multiplayerGamesUsed: 0,

}
const mutations = {
  UPDATE_PURCHASED_ITEMS(state, payload) {
    state.singleplayerGamesOwned = payload.singleplayer_games_owned
    state.multiplayerGamesOwned = payload.multiplayer_games_owned
    state.singleplayerGamesUsed = payload.singleplayer_games_used
    state.multiplayerGamesUsed = payload.multiplayer_games_used
  }
}
const actions = {
  getPurchases({ commit, dispatch }) {
    console.log(`${apiSettings.gamesdays_core_owned_api}`)
    axios.get(`${apiSettings.gamesdays_core_owned_api}`).then((response) => {
      commit('UPDATE_PURCHASED_ITEMS', response.data)
    }).catch((error) => {
      console.log(error)
      dispatch('handleError', "Failed to get purchases. Please try again.", { root: true })
    });
  }
}
const getters = {
  singleplayerGamesOwned: state => state.singleplayerGamesOwned,
  multiplayerGamesOwned: state => state.multiplayerGamesOwned,
  singleplayerGamesUsed: state => state.singleplayerGamesUsed,
  multiplayerGamesUsed: state => state.multiplayerGamesUsed,
}
const purchaseModule = {
  state,
  mutations,
  actions,
  getters
}

export default purchaseModule;