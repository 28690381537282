import axios from 'axios';
import { apiSettings } from '../../../config/api'
const state = {

  nameSuggestions: [],
  nameRandomSuggestion: null,

}
const mutations = {

  UPDATE_NAME_SUGGESTIONS(state, payload) {
    state.nameSuggestions = payload;
  },
  UPDATE_NAME_RANDOM_SUGGESTION(state, payload) {
    state.nameRandomSuggestion = payload;
  }
}
const actions = {

  getNameSuggestions({ commit, dispatch }) {
    console.log(`${apiSettings.gamesdays_core_suggest_api}`)
    axios.get(`${apiSettings.gamesdays_core_suggest_api}`).then((response) => {
      commit('UPDATE_NAME_SUGGESTIONS', response.data.suggestions)
      dispatch("updateRandomNameSuggestion")
    }).catch((error) => {
      console.log(error)
      dispatch('handleWarning', "Failed to get name suggestions. Please try again.", { root: true })
    });
  },
  updateRandomNameSuggestion({ commit, state }) {
    console.log(state.nameSuggestions)
    const index = Math.floor(Math.random() * (state.nameSuggestions.length))
    const randomSuggestion = state.nameSuggestions[index];
    commit('UPDATE_NAME_RANDOM_SUGGESTION', randomSuggestion)
  },
}
const getters = {
  nameSuggestions: state => state.nameSuggestions,
  nameRandomSuggestion: state => state.nameRandomSuggestion,
}
const namesModule = {
  state,
  mutations,
  actions,
  getters
}

export default namesModule;