<template>
  <div class="component-container">
    <!-- <div class="chat-header-container"><h3 class="chats-header p-component">Chats</h3></div> -->

    <ScrollPanel class="custombar1 chats-scrollpanel">
      <DataView :value="gameChats" layout="list" :rows="9">
        <template #empty>No chats found.</template>

        <template #list="slotProps">
          <div class="col-12">
            <ArenaChatsItem
              :contact="slotProps.data.contact"
              :job_title="slotProps.data.job_title"
              :status="slotProps.data.status"
              :messages="slotProps.data.messages"
            />
          </div>
        </template>
      </DataView>
    </ScrollPanel>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ArenaChatsItem from "@/components/arena/Arena_Chats_Item.vue";
export default {
  name: "ArenaChats",
  components: {
    ArenaChatsItem,
  },
  computed: {
    ...mapGetters(["gameChats"]),
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.chats-scrollpanel {
  width: 100%;
  height: 100%;
}

.component-container {
  // make this div fill the entire parent
  height: 100%;
  width: 100%;
  // overflow: scroll;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // align-items: stretch;
  // background-color: #f1f1f1;
  // border: 1px dashed #ccc;
}

// .chats-header {
//   background: -webkit-linear-gradient(300deg, #93f5ec 20%, #a77bf3 70%);
//   -webkit-background-clip: text;
//   background-clip: text;
//   -webkit-text-fill-color: transparent;
//   -webkit-box-decoration-break: clone;
//   font-family: "Alliance1", "Alliance1HeaderFallback", -apple-system, BlinkMacSystemFont,
//     "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
//     "Segoe UI Symbol";
//   font-feature-settings: unset;

//   font-size: 20px !important;
//   letter-spacing: -0.1em !important;
//   font-weight: 900 !important;
//   margin: auto;
//   // border: 1px solid #3f4b5b;
//   // border-width: 1px 0 0 0;
//   // background: #2a323d;
// }

.chats-header-container {
  border: 1px solid #3f4b5b;
  border-width: 1px 0 0 0;
  background: #2a323d;
}

// .unread-messages {
//     background-color: #f6f0f0;
// }

.unread-messages-wrapper {
  padding: 1px !important;
  background: -webkit-linear-gradient(300deg, #93f5ec 20%, #a77bf3 70%);
  border-radius: 5px;
}

.unread-messages-wrapper:hover {
  box-shadow: 0 0 10px #fff;
}

.unread-messages-inner {
  padding: 20px;
  background: var(--surface-card);
  border-radius: 5px;
}

.status-away {
  color: yellow;
}

.status-online {
  color: green;
}

.status-busy {
  color: red;
}

.status-offline {
  color: gray;
}

.status-unknown {
  color: black;
}

.card {
  background: #ffffff;
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 2rem;
}

.p-dropdown {
  width: 14rem;
  font-weight: normal;
}

.product-name {
  font-size: 1.1rem;
  font-weight: 700;
}

.product-description {
  margin: 0 0 1rem 0;
}

.product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.product-category {
  font-weight: 600;
  vertical-align: middle;
}

::v-deep(.product-list-item) {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;

  img {
    width: 50px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 2rem;
  }

  .product-list-detail {
    flex: 1 1 0;
  }

  .p-rating {
    margin: 0 0 0.5rem 0;
  }

  .product-price {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    align-self: flex-end;
  }

  .product-list-action {
    display: flex;
    flex-direction: column;
  }

  .p-button {
    margin-bottom: 0.5rem;
  }
}

::v-deep(.product-grid-item) {
  margin: 0.5rem;
  border: 1px solid var(--surface-border);

  .product-grid-item-top,
  .product-grid-item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  img {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 2rem 0;
  }

  .product-grid-item-content {
    text-align: center;
  }

  .product-price {
    font-size: 1.5rem;
    font-weight: 600;
  }
}

@media screen and (max-width: 576px) {
  .product-list-item {
    flex-direction: column;
    align-items: center;

    img {
      margin: 2rem 0;
    }

    .product-list-detail {
      text-align: center;
    }

    .product-price {
      align-self: center;
    }

    .product-list-action {
      display: flex;
      flex-direction: column;
    }

    .product-list-action {
      margin-top: 2rem;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}

// https://www.primefaces.org/primevue/scrollpanel
::v-deep(.p-scrollpanel) {
  p {
    padding: 0.5rem;
    line-height: 1.5;
    margin: 0;
  }

  &.custombar1 {
    .p-scrollpanel-wrapper {
      border-right: 9px solid var(--surface-ground);
    }

    .p-scrollpanel-bar {
      background-color: var(--primary-color);
      opacity: 1;
      transition: background-color 0.2s;

      &:hover {
        background-color: #007ad9;
      }
    }
  }

  &.custombar2 {
    .p-scrollpanel-wrapper {
      border-right: 9px solid var(--surface-border);
      border-bottom: 9px solid var(--surface-border);
    }

    .p-scrollpanel-bar {
      background-color: var(--surface-ground);
      border-radius: 0;
      opacity: 1;
      transition: background-color 0.2s;
    }
  }
}
</style>
