import axios from 'axios';
import { apiSettings } from '@/config/api'
import { Buffer } from 'buffer';
// import router from '../../../router'

const state = {
  certificateVerified: null
}

const mutations = {
  UPDATE_CERTIFICATE_VERIFIED(state, payload) {
    state.certificateVerified = payload
    // console.log("certificate verified: " + state.certificateVerified);
  }
}

const actions = {

  resetVerified({ commit }) {
    commit('UPDATE_CERTIFICATE_VERIFIED', null)
  },

  verifyCertificate({ commit, dispatch }, payload) {

    console.log("verifying certificate");

    const player_id = payload.player_id;
    const game_id = payload.game_id;
    const score = payload.score;
    const signature = payload.signature;

    const safe_player_id = encodeURIComponent(player_id)

    axios
      .get(
        `${apiSettings.gamesdays_core_verify_api}?player_id=${safe_player_id}&game_id=${game_id}&score=${score}&signature=${signature}`
      )
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);

          if (error.response.status == 400) {
            console.log("400");
          }
        }
        // handle error
        console.log(error);
        dispatch("handleError", "Failed to verify the certificate. Please try again.")
      }).then((response) => {
        console.log(response.data);
        var verified = response.data.verified;
        console.log("verified: " + verified);
        commit('UPDATE_CERTIFICATE_VERIFIED', verified)
      });

  },


  openCertificate({ rootState, dispatch }, game_id) {
    console.log(game_id);
    console.log("getting certificate for game id: " + game_id);
    console.log(rootState);
    const user = rootState.oidcStore.user
    console.log(`user email: ${user.email}`)
    console.log(`user email: ${user.email}`);
    const safe_email = encodeURIComponent(user.email);
    // handle 409 and 404 errors. We expect 409 if the user has already requested a certificate
    // and 404 if the user is not a player in the game
    // and 200 if sucessful

    axios
      .get(
        `${apiSettings.gamesdays_core_game_api}/${game_id}/certificate/${safe_email}`
      )
      .then((response) => {
        // handle 409 and 404
        console.log(response.data);

        var cert_base64 = response.data.certificate_base64;
        console.log(typeof cert_base64)

        const pdfData = Buffer.from(cert_base64, 'base64'); // Decode the base64 string

        // Create a blob from the decoded data
        const blob = new Blob([pdfData], { type: "application/pdf" });
        // set fileName to game_id + certificate.pdf
        const fileName = `${game_id}_certificate.pdf`;
        // Create a download link
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;

        // Trigger the download
        link.click();
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);

          if (error.response.status == 409) {
            console.log("409");
            // router.push({ path: `/certificate/${rootState.arena.gameId}` })
          }
          if (error.response.status == 404) {
            console.log("404");
            // router.push({ path: `/certificate/${rootState.arena.gameId}` })
          }
        }
        // handle error
        console.log(error);
        dispatch("handleError", "Failed to get the certificate. Please try again.")
      });
  },

}

const getters = {
  certificateVerified: (state) => state.certificateVerified
}

const certificateModule = {
  state,
  actions,
  getters,
  mutations,
}

export default certificateModule;