<template>
  <div class="container">
    <div class="item">
      <Loading />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Loading from "@/components/core/Loading.vue";

export default {
  name: "LoadingView",
  components: {
    Loading,
  },
};
</script>
<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
}

.item {
  width: 50%;
  height: 50%;
  /* border: 1px dashed greenyellow; */
}
</style>
