<template>
  <!-- <div class="component-container"> -->
    <Avatar :image="avatar" size="large" shape="circle" />
  <!-- </div> -->
</template>
<script>
import jenkins_avatar from "../../../public/tools/jenkins.svg";
import greg_avatar from "../../../public/contacts/Greg.png";
import john_avatar from "../../../public/contacts/John.png";
import bill_avatar from "../../../public/contacts/Bill.png";
import sue_avatar from "../../../public/contacts/Sue.png";
import sally_avatar from "../../../public/contacts/Sally.png";

export default {
  name: "ArenaAvatar",
  components: {},
  props: {
    contact: String,
  },
  computed: {
    avatar() {
      // check if tool is in the avatars dictionary and return the icon. if avatars, return a default avatars
      return this.avatars[this.contact]
        ? this.avatars[this.contact]
        : this.avatars["jenkins"];
    },
  },
  data() {
    return {
      //dictionary of tool icons
      avatars: {
        jenkins: jenkins_avatar,
        Greg: greg_avatar,
        John: john_avatar,
        Bill: bill_avatar,
        Sue: sue_avatar,
        Sally: sally_avatar,
      },
    };
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// .component-container {
//   // make this div fill the entire parent
//   // height: 100%;
//   // width: 100%;
//   // overflow: scroll;
//   // // display: flex;
//   // // flex-direction: column;
//   // // justify-content: space-between;
//   // // align-items: stretch;
//   // // background-color: #f1f1f1;
//   // border: 1px dashed #ccc;
//   margin-bottom: 2px;
// }
</style>
