<template>
  <div class="component-container">
    <div class="container">
      <div class="progress-area">
        <div class="status-progress">
          <ProgressBar :value="gameProgress" />
        </div>
      </div>
      <div class="score-area">
        <div class="score-users-container">
          <div class="score-users-item">
            <div class="status-livescore">{{ gameScore }}/{{ gameScoreTrend }}</div>
          </div>
          <div class="score-users-item">
            <div class="status-metric">score/trend</div>
          </div>
        </div>
      </div>
      <div class="users-area">
        <div class="score-users-container">
          <div class="score-users-item">
            <div class="status-livescore">{{ gameOnlineUsers }}/{{ gameRPS }}</div>
          </div>
          <div class="score-users-item">
            <div class="status-metric">online/rps</div>
          </div>
        </div>
      </div>
      <div class="ratios-area">
        <Chart
          ref="chart"
          type="doughnut"
          class="ratios-chart"
          :data="chartData"
          :options="{
            responsive: false,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'right',
              },
              title: {
                display: true,
                text: 'User ratio(s)',
              },
              animation: false,
              animations: {
                duration: 0,
              },
            },
          }"
        />
        <!-- <div>
          <div class="row">
            <div class="col-2" v-for="(ratio, index) in gameOnlineUserRatios" :key="index">
              <div>{{ ratio.user_type }} {{ ratio2percent(ratio.ratio) }}%</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "ArenaStatus",

  computed: {
    ...mapGetters([
      "gameProgress",
      "gameScore",
      "gameScoreTrend",
      "gameOnlineUsers",
      "gameRPS",
      "gameOnlineUserRatios",
    ]),
    chartData() {
      console.log("redrawing chart");
      return {
        labels: ["NotInterested", "Free", "Premium", "AntiGravBoards", "BlueFusion"],
        datasets: [
          {
            label: "User Types",
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#00FF7F", "#8B008B"],

            data: this.gameOnlineUserRatios,
          },
        ],
      };
    },
  },
  methods: {
    ratio2percent(ratio) {
      return Math.round(ratio * 100);
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.ratios-chart {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: 1rem;
}

.component-container {
  // make this div fill the entire parent
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  //uncomment for debug
  // border: 1px dashed #ccc;
}

.status-livescore {
  background: -webkit-linear-gradient(300deg, #93f5ec 20%, #a77bf3 70%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  font-family: "Alliance1", "Alliance1HeaderFallback", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-feature-settings: unset;

  font-size: 42px !important;
  letter-spacing: -0.1em !important;
  font-weight: 900 !important;
  margin: auto;
}

.status-metric {
  background: -webkit-linear-gradient(300deg, #93f5ec 20%, #a77bf3 70%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  font-family: "Alliance1", "Alliance1HeaderFallback", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-feature-settings: unset;

  font-size: 20px !important;
  letter-spacing: -0.1em !important;
  font-weight: 900 !important;
  margin: auto;
}

// start https://grid.layoutit.com/?id=bcCN6Wz
.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "score-area score-area score-area score-area score-area score-area ratios-area ratios-area ratios-area ratios-area ratios-area"
    "score-area score-area score-area score-area score-area score-area ratios-area ratios-area ratios-area ratios-area ratios-area"
    "score-area score-area score-area score-area score-area score-area ratios-area ratios-area ratios-area ratios-area ratios-area"
    "users-area users-area users-area users-area users-area users-area ratios-area ratios-area ratios-area ratios-area ratios-area"
    "users-area users-area users-area users-area users-area users-area ratios-area ratios-area ratios-area ratios-area ratios-area"
    "users-area users-area users-area users-area users-area users-area ratios-area ratios-area ratios-area ratios-area ratios-area"
    "progress-area progress-area progress-area progress-area progress-area progress-area progress-area progress-area progress-area progress-area progress-area";
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
}

.progress-area {
  grid-area: progress-area;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.score-area {
  grid-area: score-area;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.users-area {
  grid-area: users-area;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.ratios-area {
  grid-area: ratios-area;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

// end https://grid.layoutit.com/?id=bcCN6Wz

.score-users-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.score-users-item {
  flex-basis: 50%;
}
</style>
