<template>
  <div class="rainbow3_copilot">
    <span>Loading...</span>
  </div>
</template>
<script>
export default {
  name: "Loading",
};
</script>
<style scoped lang="scss">
@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

.rainbow3_copilot span::after {
  content: "";
  width: 35px;
  height: 60px;
  // background: #ec7fff;
  background: #a77bf3;
  //background: rgb(56, 189, 248);
  display: inline-block;
  animation: cursor-blink 1.5s steps(2) infinite;
  margin-left: 12px;
}

.rainbow3_copilot {
  background: -webkit-linear-gradient(300deg, #93f5ec 20%, #a77bf3 70%);
  //background: -webkit-linear-gradient(rgb(56, 189, 248), rgb(186, 230, 253));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  font-family: "Alliance1", "Alliance1HeaderFallback", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-feature-settings: unset;

  font-size: 84px !important;
  // line-height: 48px !important;
  letter-spacing: -0.1em !important;
  font-weight: 900 !important;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.1em;
  padding-bottom: 0.125em;
}
.home-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
</style>
