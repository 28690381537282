<template>
  <div class="component-container">
    <div class="tools-container">
      <ArenaToolsButton text="Jenkins" tool="jenkins" :gameId="gameId" />
      <ArenaToolsButton text="Gitea" tool="gitea" :gameId="gameId" />
      <ArenaToolsButton text="Nexus" tool="nexus" :gameId="gameId" />
      <ArenaToolsButton text="Grafana" tool="grafana" :gameId="gameId" />
      <ArenaToolsButton text="Jaeger" tool="jaeger" :gameId="gameId" />
      <ArenaToolsButton text="Terminal" tool="terminal" :gameId="gameId" />
      <ArenaToolsButton text="Test" tool="test" :gameId="gameId" />
      <ArenaToolsButton text="Prod" tool="prod" :gameId="gameId" />
      <ArenaToolsButton text="Dev" tool="dev" :gameId="gameId" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ArenaToolsButton from "@/components/arena/Arena_ToolsButton.vue";

export default {
  name: "ArenaToolsMenu",
  components: {
    ArenaToolsButton
},
  computed: {
    ...mapGetters(["gameId"]),
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.component-container {
  // make this div fill the entire parent
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  //uncomment for debug
  // border: 1px dashed #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tools-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
</style>