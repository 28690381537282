<template>
  <div class="comps">
    <Navbar />
    <div class="card">
      <div class="comp-new">
        <Button label="New" icon="pi pi-plus" @click="visible = true" />
      </div>

      <p>
        Create a new competition to get started. You can create as many competitions as
        you like. Each competition can have as many or as few games as you like. A game
        can appear in mutliple competitions. Competitions run for a fixed period of time.
        Games must be played within the competition windows. After this time the
        competition will be closed and the leaderboard will be final. Playing a game after
        the competition has closed will not be counted towards the competition.
        Competition cannot be changed once they are created. You must be and admin or a
        player in the game to add it to a competition.
      </p>
      <DataView
        :value="compItems"
        :layout="layout"
        :paginator="true"
        :rows="9"
        :sortOrder="sortOrder"
        :sortField="sortField"
      >
        <template #empty>No competitions found.</template>
        <template #header>
          <div class="grid grid-nogutter">
            <div class="col-6 cart-header" style="text-align: left">Competitions</div>
          </div>
        </template>

        <template #list="slotProps">
          <div class="col-12">
            <div class="comp-list-item">
              <img :src="'products/competition_64.png'" :alt="slotProps.data.comp_id" />
              <div class="comp-list-detail">
                <div class="comp-name">{{ slotProps.data.name }}</div>
                <div class="comp-description">
                  {{ slotProps.data.comp_id }}
                </div>
                <!-- <i class="pi pi-cog comp-category-icon"></i> -->
                <span class="comp-category">{{ slotProps.data.status }}</span>
                <div>
                  <span class="comp-start-end"
                    >Starts on {{ formateDate(slotProps.data.not_before) }} and ends on
                    {{ formateDate(slotProps.data.not_after) }}</span
                  >
                </div>
              </div>
              <div class="comp-list-action">
                <Button
                  label="Leaderboard"
                  icon="pi pi-folder-open"
                  @click="openLeaderboard(slotProps.data.comp_id)"
                />
              </div>
            </div>
          </div>
        </template>
      </DataView>
      <!-- <CompetitionCreate /> -->
      <div class="comp-create-dialog">
        <Dialog
          v-model:visible="visible"
          modal
          :style="{ width: '60rem' }"
          :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
        >
          <CompetitionCreate @close-dialog="closeDialog" />
        </Dialog>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import CompetitionCreate from "@/components/competition/Competition_Create";
import Navbar from "@/components/core/Navbar";

export default {
  name: "Comps",
  components: {
    CompetitionCreate,
    Navbar,
  },
  data() {
    return {
      layout: "list",
      sortKey: null,
      sortOrder: null,
      sortField: null,
      sortOptions: [
        { label: "Price High to Low", value: "!price" },
        { label: "Price Low to High", value: "price" },
      ],
      visible: false,
    };
  },
  computed: {
    ...mapGetters(["compItems"]),
  },
  created() {
    console.log("Comps created");
    this.$store.dispatch("getCompItems");
  },
  // mounted() {
  //   this.$store.dispatch("startUpdateCompState");
  // },
  // beforeUnmount() {
  //   this.$store.dispatch("stopUpdateCompState");
  // },
  methods: {
    ...mapActions(["openLeaderboard"]),
    createDisabled(status) {
      console.log(status);
      if (status === "created") {
        return false;
      } else {
        return true;
      }
    },
    formateDate(date) {
      // return just the date not the time in local format
      return new Date(date).toLocaleDateString();
    },
    openDisabled(status) {
      console.log(status);
      //TODO: update logic here for when we should be able to open a game
      if (
        status === "starting" ||
        status === "started" ||
        status === "provisioning" ||
        status === "running" ||
        status === "destroying" ||
        status === "completed"
      ) {
        return false;
      } else {
        return true;
      }
    },
    closeDialog() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.comp-create-dialog {
  width: 50rem;
  height: 50rem;
}

.card {
  //background: #ffffff;
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 2rem;
}

// .p-dropdown {
//   width: 14rem;
//   font-weight: normal;
// }

.comp-name {
  font-size: 1.5rem;
  font-weight: 700;
}

.comp-description {
  margin: 0 0 1rem 0;
}

// .comp-category-icon {
//   vertical-align: middle;
//   margin-right: 0.5rem;
// }

.comp-category {
  font-weight: 600;
  vertical-align: middle;
}

.comp-new {
  margin-bottom: 1rem;
  // border-bottom: 1px solid var(--surface-border);
}

::v-deep(.comp-list-item) {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;

  img {
    width: 50px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 2rem;
  }

  .comp-list-detail {
    flex: 1 1 0;
  }

  // .p-rating {
  //   margin: 0 0 0.5rem 0;
  // }

  // .comp-price {
  //   font-size: 1.5rem;
  //   font-weight: 600;
  //   margin-bottom: 0.5rem;
  //   align-self: flex-end;
  // }

  .comp-list-action {
    display: flex;
    flex-direction: column;
  }

  .p-button {
    margin-bottom: 0.5rem;
  }
}

// ::v-deep(.comp-grid-item) {
//   margin: 0.5rem;
//   border: 1px solid var(--surface-border);

//   .comp-grid-item-top,
//   .comp-grid-item-bottom {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//   }

//   img {
//     box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
//     margin: 2rem 0;
//   }

//   .comp-grid-item-content {
//     text-align: center;
//   }

//   .comp-price {
//     font-size: 1.5rem;
//     font-weight: 600;
//   }
// }

@media screen and (max-width: 576px) {
  .comp-list-item {
    flex-direction: column;
    align-items: center;

    img {
      margin: 2rem 0;
    }

    .comp-list-detail {
      text-align: center;
    }

    // .comp-price {
    //   align-self: center;
    // }

    .comp-list-action {
      display: flex;
      flex-direction: column;
    }

    .comp-list-action {
      margin-top: 2rem;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}
</style>
