<template>
  <div class="faq">
    <Navbar />
    <h1>Frequently Asked Questions</h1>
    <p>Here are some common questions we get asked</p>
    <div class="faqs-container">
      <Faq
        question="It seems too hard! Can I get a refund?"
        answer="Unfortunately we do not offer refunds in this case. DevOps is a complex subject and some people will struggle. The game has to be challenging to be useful"
      />
      <Faq
        question="It seems too easy! Can I get a refund?"
        answer="Unfortunately we do not offer refunds in this case. We are limited in how complex we can make the game. We have to cater for a wide range of skill levels and there are practical limits. Some top engineers will find the game easy but even amongst the best of the best someone will be able to squeeze out a few more point on the leaderboard than everyone else."
      />
      <Faq
        question="Is there a free version?"
        answer="Not yet. We are working on a free tier but for now we have to charge to cover the costs of running the game servers."
      />
      <Faq
        question="It seems expensive! Why does it cost so much?"
        answer="We run the game servers on Azure. We have to pay for the VMs, the storage, the network traffic. We are working on ways to reduce the cost but we want to provide a high quality service."
      />
      <Faq
        question="Why does it take so long to start a game?"
        answer="It takes time to start a VM on azure. We pre bake as much as we can but it still needs to copy the machine image. Once the VMs are up we need to config the CICD tool chain. This is all automated but it takes time. We are working on ways to speed this up."
      />
      <Faq
        question="I think I found a bug. Can I get a refund?"
        answer="Contact us with the details and we will investigate. If it is a bug we will fix it and give you a free game."
      />
      <Faq
        question="I think the was some kind of outage. Can I get a refund?"
        answer="Contact us with the details and we will investigate. If there has been a problem with the underlying azure infrastructure we'll give you a free game."
      />
      <Faq
        question="Can I get the source code?"
        answer="The source code is not available. We want to make sure that the game is secure and that the game is not cheated."
      />
      <Faq
        question="Why do you use Azure?"
        answer="We use Azure because it is very common in the industry. We want to make sure that the skills you learn are directly applicable to the real world. We are working on supporting other cloud providers."
      />
      <Faq
        question="I really dislike Jenkins and there are better tools these day. Why not use something else?"
        answer="Jenkins seems to get a lot of negative comments online. Opinions vary but it is still very widely used in the industry. Lots of the criticisms of jenkins can be reduced with use of JCaaS and the the kubernetes plugin. This is the setup we have deployed to the arena which is easy to use. Having said that we are working on supporting other CICD tools."
      />
      <Faq
        question="I think I found a security issue. How do I report it?"
        answer="Please get in touch with us at security@game-days.io. We take security very seriously and will investigate any reports."
      />
      <Faq
        question="What data do you store about me?"
        answer="As little as possible because it costs to store and secure it so its better for everyone if we hold the absolute minimum. We store your email address and your score. We use stripe to process all payments so we do not store any payment information. We purge the data after 30 days of inactivity."
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
// @ is an alias to /src
import Navbar from "@/components/core/Navbar";
import Faq from "@/components/core/Faq.vue";

export default {
  name: "FaqView",
  components: {
    Navbar,
    Faq,
  },
  methods: {
    ...mapActions(["handleError", "handleWarning", "handleInfo", "handleSuccess"]),
  },
};
</script>
<style scoped lang="scss">
.faqs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.faq h1 {
  font-family: "Alliance1", "Alliance1HeaderFallback", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-feature-settings: unset;
  font-size: 56px !important;
  line-height: 60px !important;
  letter-spacing: -0.03em !important;
  font-weight: 800 !important;
  display: block;

  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 0.2rem;
}

.faq p {
  font-family: "Alliance1", "Alliance1Fallback", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-feature-settings: "ss02" on, "ss01" on;
  font-weight: 400;
  font-size: 20px !important;
  line-height: 28px !important;
  text-align: center;
}
</style>
