<template>
  <div class="confirm">
    <Navbar />

    <div class="card">
      <main>
        <h1>Confirm</h1>
      </main>
    </div>
  </div>
</template>
<script>
//import { mapGetters } from "vuex";

import Navbar from "@/components/core/Navbar";

export default {
  name: "ConfirmView",
  components: {
    Navbar,
  },
  data() {
    return {};
  },
  computed: {},
  async mounted() {
    console.log("mounted");
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.card {
  // background: #ffffff;
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 2rem;
}

// .p-dropdown {
//   width: 14rem;
//   font-weight: normal;
// }

// .product-name {
//   font-size: 1.5rem;
//   font-weight: 700;
// }

// .cart-header {
//   font-size: 1.5rem;
//   font-weight: 700;
// }

// .cart-total {
//   font-size: 1.5rem;
//   font-weight: 700;
// }

// .product-description {
//   margin: 0 0 1rem 0;
// }

// .product-category-icon {
//   vertical-align: middle;
//   margin-right: 0.5rem;
// }

// .product-category {
//   font-weight: 600;
//   vertical-align: middle;
// }

// ::v-deep(.product-list-item) {
//   display: flex;
//   align-items: center;
//   padding: 1rem;
//   width: 100%;

//   img {
//     width: 50px;
//     box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
//     margin-right: 2rem;
//   }

//   .product-list-detail {
//     flex: 1 1 0;
//   }

//   .p-rating {
//     margin: 0 0 0.5rem 0;
//   }

//   .product-price {
//     font-size: 1.5rem;
//     font-weight: 600;
//     margin-bottom: 0.5rem;
//     align-self: flex-end;
//   }

//   .product-list-action {
//     display: flex;
//     flex-direction: column;
//   }

//   .p-button {
//     margin-bottom: 0.5rem;
//   }
// }

// ::v-deep(.product-grid-item) {
//   margin: 0.5rem;
//   border: 1px solid var(--surface-border);

//   .product-grid-item-top,
//   .product-grid-item-bottom {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//   }

//   img {
//     box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
//     margin: 2rem 0;
//   }

//   .product-grid-item-content {
//     text-align: center;
//   }

//   .product-price {
//     font-size: 1.5rem;
//     font-weight: 600;
//   }
// }

// @media screen and (max-width: 576px) {
//   .product-list-item {
//     flex-direction: column;
//     align-items: center;

//     img {
//       margin: 2rem 0;
//     }

//     .product-list-detail {
//       text-align: center;
//     }

//     .product-price {
//       align-self: center;
//     }

//     .product-list-action {
//       display: flex;
//       flex-direction: column;
//     }

//     .product-list-action {
//       margin-top: 2rem;
//       flex-direction: row;
//       justify-content: space-between;
//       align-items: center;
//       width: 100%;
//     }
//   }
// }
</style>
