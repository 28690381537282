<template>
  <div class="container">
    <div class="item">
      <Loading />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Loading from "@/components/core/Loading.vue";

export default {
  name: "OidcCallback",
  components: {
    Loading,
  },
  methods: {
    ...mapActions(["oidcSignInCallback"]),
  },
  mounted() {
    console.log("sign in callback");
    this.oidcSignInCallback()
      .then((redirectPath) => {
        console.log("redirectPath");
        this.$router.push(redirectPath);
      })
      .catch((err) => {
        console.error(err);
        this.$router.push("/oidc-callback-error"); // Handle errors any way you want
      });
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
}

.item {
  width: 50%;
  height: 50%;
  /* border: 1px dashed greenyellow; */
}
</style>
