<template>
  <div class="landing">
    <h1>Your technical proving ground</h1>
    <p class="strapline">
      We create arenas on cloud infrastructure where you can showcase your technical
      skills.
    </p>

    <Card class="first-block">
      <template #content>
        <p class="m-0">
          Want to show off your skills or just see if you are ready for a job? Want to see
          how you compare to your peers? This is the place for you. You can play as an
          individual or as a team. You can even create a competition for your
          organization. If you are hiring you can also use the game as a technical
          interview tool to see what your candidate can do.
        </p>
      </template>
    </Card>

    <div class="grid">
      <div class="col-12 md:col-6 lg:col-6 xl:col-3">
        <Card class="option">
          <template #header>
            <img class="card-img" alt="individual" src="products/singleplayer_64.png" />
          </template>
          <template #title>Individual</template>
          <template #subtitle>Work alone to showcase your skills</template>
          <template #content>
            <p>
              In single player mode an arena is provisioned and a game is started. As part
              of the game you will need to perform tasks which are typical for a "DevOps"
              engineer. There will be live traffic to a site deployed and you must manage
              through changes need to support the business. There might be incidents to
              deal with as well. The quicker you work the higher the score. If you miss a
              requirement or the site goes down so does your score.
            </p>
          </template>
        </Card>
      </div>
      <div class="col-12 md:col-6 lg:col-6 xl:col-3">
        <Card class="option">
          <template #header>
            <img class="card-img" alt="team" src="products/multiplayer_64.png" />
          </template>
          <template #title>Team</template>
          <template #subtitle>Work as a team to hit a new high score</template>
          <template #content>
            <p class="m-0">
              In team mode an arena is provisioned and and up to 4 players can access the
              game. You will need to perform tasks as in the single player game but can
              use you combined skills and experience. This can be a good way to build a
              team or to give juniors the chance to shine under the watch of a senior
              engineer without risking production.
            </p>
          </template>
        </Card>
      </div>
      <div class="col-12 md:col-6 lg:col-6 xl:col-3">
        <Card class="option">
          <template #header>
            <img class="card-img" alt="comptetion" src="products/competition_64.png" />
          </template>
          <template #title>Competition</template>
          <template #subtitle>Find the best teams in your organization</template>
          <template #content>
            <p class="m-0">
              Have lots of teams in your organization? Try creating a competition for fun
              to see which team performs the best. Why not mix teams to help spread ideas
              across multiple teams and build new contacts and relationships.
            </p>
          </template>
        </Card>
      </div>
      <div class="col-12 md:col-6 lg:col-6 xl:col-3">
        <Card class="option">
          <template #header>
            <img class="card-img" alt="comptetion" src="products/interview_64.png" />
          </template>
          <template #title>Technical Interview</template>
          <template #subtitle>See what your candidate can do</template>
          <template #content>
            <p class="m-0">
              Sometimes it can be tricky to know if a candidate can do what they say. Play
              a short game where they can show you there skills and how they work. You can
              discuss what they are doing as the game progresses to get a feel for their
              level of understanding.
            </p>
          </template>
        </Card>
      </div>
    </div>

    <div class="how-container">
      <h1>How it works!</h1>

      <Fieldset
        legend="Step 1: Create an arena through the web interface"
        class="how-it-works"
      >
        <p class="m-0">
          Once you create a game and click the start button we'll use our automation to
          spin up a VM on the cloud with a single node k8s cluster. We'll deploy and
          intergrated CI/CD tools to the cluster which you can use to deploy the apps.
        </p>
      </Fieldset>

      <Fieldset
        legend="Step 2: Use your skills to fix problems and help deliver features"
        class="how-it-works"
      >
        <p class="m-0">
          You will be given a set of tasks to complete. These tasks will be typical of
          what you would do in a "DevOps" role. You will need to use git, docker, k8s,
          helm and jenkins to complete the tasks. You will also need to debug some python
          code.
        </p>
      </Fieldset>

      <Fieldset
        legend="Step 3: We'll simiulate load and incidents to see how you cope"
        class="how-it-works"
      >
        <p class="m-0">
          We'll simulate load on the site and incidents to see how you cope. You will need
          to make changes to the site to support the load and fix the incidents. You will
          be scored on how well you do.
        </p>
      </Fieldset>

      <Fieldset
        legend="Step 4: Ramp up to peak load and see how you cope"
        class="how-it-works"
      >
        <p class="m-0">
          We'll ramp up the load on the site to see how you cope. You will need to make
          eveything as efficient as possible to support the load. The more load you handle
          the higher your score
        </p>
      </Fieldset>

      <Fieldset legend="Step 5: Destroy the arena" class="how-it-works">
        <p class="m-0">
          When the time is up we'll destroy the arena and the infrastructure will be
          removed.
        </p>
      </Fieldset>

      <Fieldset legend="Step 6: View you score" class="how-it-works">
        <p class="m-0">
          We'll update our leaderboard with your score and you can see how you compare to
          everyone else. If you choose to play privately we'll only update your score for
          you to see.
        </p>
      </Fieldset>

      <Fieldset legend="Step 7: Download you certificate" class="how-it-works">
        <p class="m-0">
          You can download a certificate to show you have completed the game. You can use
          this to show potential employers what you can do.
        </p>
      </Fieldset>

      <!-- <div>
        <h2>Skill that will be tested</h2>

        <p>Basic Docker usage</p>
        <p>Basic Kubernetes usage</p>
        <p>Basic Helm usage</p>
        <p>Basic Python</p>
      </div> -->
    </div>
    <div class="skills">
      <h1>Skills</h1>
      <Carousel
        :value="skills"
        :numVisible="3"
        :numScroll="1"
        :responsiveOptions="responsiveOptions"
        circular
        :autoplayInterval="8000"
      >
        <template #item="slotProps">
          <div
            class="border border-surface-200 dark:border-surface-700 rounded skills-container flex flex-column md:flex-row md:align-items-center justify-content-center dots"
          >
            <div class="mb-4 skills-item dots ml-6">
              <div class="relative mx-auto">
                <div class="skills-title">
                  {{ slotProps.data.name }}
                </div>
                <div class="image-wrapper rounded">
                  <img :src="slotProps.data.image" :alt="slotProps.data.name" />
                  <Rating
                    v-model="slotProps.data.importance"
                    readonly
                    :cancel="false"
                    class="skills-rating"
                  />
                </div>
              </div>
            </div>
            <div class="flex justify-between items-center skills-item dots ml-6">
              <div class="mt-0 font-semibold text-xl">
                <!-- loop through  {{ slotProps.data.description }} and display each on new line -->
                <ul class="skills-list">
                  <li v-for="desc in slotProps.data.description" :key="desc">
                    <div class="skills-container-desc">
                      <div class="skills-item-desc skills-item-desc-left">
                        {{ desc.text }}
                      </div>
                      <div class="skills-item-desc skills-item-desc-right">
                        <Button
                          class="skills-btn3 p-button-secondary"
                          @click="launchLearning(desc.link)"
                          severity="contrast"
                          icon="pi pi-external-link"
                          aria-label="Open"
                        >
                        </Button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </template>
      </Carousel>
    </div>
  </div>
</template>
<script>
// import LogoPrompt from "@/components/core/LogoPrompt.vue";
import jenkins_icon from "../../../public/tools/jenkins.svg";
import gitea_icon from "../../../public/tools/gitea.svg";
import nexus_icon from "../../../public/tools/nexus3_new.png";
import linux_icon from "../../../public/tools/terminal.png";
import git_icon from "../../../public/tools/git-white.png";
import grafana_icon from "../../../public/tools/grafana.svg";
import jaeger_icon from "../../../public/tools/jaeger.svg";
import prometheus_icon from "../../../public/tools/prometheus-logo.svg";
import loki_icon from "../../../public/tools/loki.png";
import k8s_icon from "../../../public/tools/kubernetes-512x512.png";
import docker_icon from "../../../public/tools/docker.svg";
import python_icon from "../../../public/tools/python.svg";
import helm_icon from "../../../public/tools/helm-blue-vector.svg";

export default {
  name: "Landing",
  // components: {
  //   LogoPrompt,
  // },
  methods: {
    launchLearning(link) {
      window.open(link, "_blank");
    },
  },

  data() {
    return {
      //dictionary of tool icons
      skills: [
        {
          id: "1",
          name: "Linux",
          description: [
            {
              link: "https://tldp.org/LDP/Bash-Beginners-Guide/html/sect_02_03.html",
              text: "Bash script debugging",
            },
            {
              link: "https://tldp.org/HOWTO/Bash-Prog-Intro-HOWTO.html",
              text: "Bash CLI",
            },
            {
              link: "https://www.redhat.com/sysadmin/introduction-vi-editor",
              text: "Edit text files with vi",
            },
          ],
          image: linux_icon,
          importance: 5,
        },
        {
          id: "2",
          name: "Git",
          description: [
            {
              link: "https://git-scm.com/docs/git-clone",
              text: "Clone repo",
            },
            {
              link: "https://git-scm.com/docs/git-commit",
              text: "Commit changes and push",
            },
            {
              link:
                "https://docs.github.com/en/pull-requests/collaborating-with-pull-requests/addressing-merge-conflicts/resolving-a-merge-conflict-using-the-command-line?platform=linux",
              text: "Resolve merge conflicts",
            },
          ],
          image: git_icon,
          importance: 4,
        },
        {
          id: "3",
          name: "Python",
          description: [
            {
              link: "https://www.w3schools.com/python/default.asp",
              text: "Read and understand python code",
            },
            {
              link: "https://flake8.pycqa.org/en/latest/",
              text: "Lint python code (flake8)",
            },
            {
              link: "https://black.readthedocs.io/en/stable/",
              text: "Format python code (black)",
            },
            {
              link: "https://flask.palletsprojects.com/en/3.0.x/",
              text: "Flask web framework",
            },
          ],
          image: python_icon,
          importance: 4,
        },
        {
          id: "4",
          name: "K8s",
          description: [
            {
              link:
                "https://kubernetes.io/docs/tutorials/kubernetes-basics/deploy-app/deploy-intro/",
              text: "Deploy applications",
            },
            {
              link:
                "https://kubernetes.io/docs/tutorials/kubernetes-basics/scale/scale-intro/",
              text: "Scale applications",
            },
            {
              link:
                "https://kubernetes.io/docs/tutorials/kubernetes-basics/explore/explore-intro/",
              text: "Troubleshoot applications",
            },
          ],
          image: k8s_icon,
          importance: 5,
        },
        {
          id: "5",
          name: "Helm",
          description: [
            {
              link:
                "https://helm.sh/docs/intro/using_helm/#helm-install-installing-a-package",
              text: "Deploy charts",
            },
            {
              link: "https://helm.sh/docs/intro/using_helm/#creating-your-own-charts",
              text: "Build charts",
            },
          ],
          image: helm_icon,
          importance: 3,
        },
        {
          id: "6",
          name: "Docker",
          description: [
            {
              link: "https://docs.docker.com/reference/dockerfile/",
              text: "Troubleshoot Dockerfile issues",
            },
            {
              link:
                "https://github.com/GoogleContainerTools/kaniko?tab=readme-ov-file#kaniko---build-images-in-kubernetes",
              text: "Build images in k8s",
            },
            {
              link: "https://docs.docker.com/engine/reference/commandline/docker/",
              text: "Docker CLI",
            },
          ],
          image: docker_icon,
          importance: 5,
        },
        {
          id: "7",
          name: "Jenkins",
          description: [
            {
              link: "https://www.jenkins.io/doc/book/pipeline/jenkinsfile/",
              text: "Declarative pipelines (JenkinsFile)",
            },
            {
              link: "https://www.jenkins.io/doc/book/pipeline/shared-libraries/",
              text: "Jenkins shared libraries",
            },
            {
              link: "https://www.jenkins.io/doc/book/blueocean/pipeline-run-details/",
              text: "Troubleshooting failed jobs",
            },
          ],
          image: jenkins_icon,
          importance: 4,
        },
        {
          id: "8",
          name: "Gitea",
          description: [
            {
              link: "https://docs.github.com/en/get-started/using-github/github-flow",
              text: "Github flow",
            },
            {
              link: "https://docs.gitea.com/usage/pull-request",
              text: "PR review/merge",
            },
          ],
          image: gitea_icon,
          importance: 2,
        },

        {
          id: "9",
          name: "Nexus",
          description: [
            {
              link:
                "https://help.sonatype.com/en/browsing-repositories-and-repository-groups.html",
              text: "Administer docker images",
            },
          ],
          image: nexus_icon,
          importance: 1,
        },
        {
          id: "10",
          name: "Prometheus",
          description: [
            {
              link: "https://prometheus.io/docs/prometheus/latest/querying/basics/",
              text: "Query Prometheus metrics",
            },
          ],
          image: prometheus_icon,
          importance: 1,
        },
        {
          id: "11",
          name: "Grafana",
          description: [
            {
              link: "https://grafana.com/docs/grafana/latest/getting-started/",
              text: "Interpret Grafana dashboards",
            },
          ],
          image: grafana_icon,
          importance: 1,
        },
        {
          id: "12",
          name: "Jaeger",
          description: [
            {
              link: "https://www.jaegertracing.io/docs/1.58/getting-started/",
              text: "Explore traces",
            },
          ],
          image: jaeger_icon,
          importance: 1,
        },
        {
          id: "13",
          name: "Loki",
          description: [
            {
              link: "https://grafana.com/docs/loki/latest/get-started/",
              text: "Explore logs",
            },
          ],
          image: loki_icon,
          importance: 1,
        },
      ],
      responsiveOptions: [
        {
          breakpoint: "1400px",
          numVisible: 2,
          numScroll: 1,
        },
        {
          breakpoint: "1199px",
          numVisible: 3,
          numScroll: 1,
        },
        {
          breakpoint: "767px",
          numVisible: 2,
          numScroll: 1,
        },
        {
          breakpoint: "575px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.dots {
  // border-style: dotted;
  // border-color: greenyellow;
  // border-width: 2px;
}

.image-wrapper {
  width: 200px; /* Adjust to your desired width */
  height: 200px; /* Adjust to your desired height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // overflow: hidden;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.image-wrapper img {
  max-width: 80%;
  max-height: 80%;
  min-width: 80%;
  min-height: 80%;
  // object-fit: cover;
}

// .skills-container {
//   display: flex;
//   // flex-direction: column;
//   justify-content: space-between;
//   align-items: center;
//   padding: 1rem;
// }

.skills-item {
  width: 100%;
}

.skills-container-desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.skills-item-desc {
  // width: 50%;
  margin-right: 1rem;
  justify-content: center;
}

.skills-item-desc-left {
  width: 80%;
  font-size: 0.9rem;
}
.skills-item-desc-right {
  width: 20%;
}

.skills-title {
  font-family: "Alliance1", "Alliance1HeaderFallback", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-feature-settings: unset;
  font-size: 46px !important;
  line-height: 60px !important;
  letter-spacing: -0.03em !important;
  font-weight: 800 !important;
  display: block;

  // margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: #ffffff;
  // text-align: center;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
}

.skills-list {
  list-style-type: none;
  padding-inline-start: 0px;
}

.skills-btn3 {
  // margin-top: 1rem;
}

.skills-rating {
  //float the rating over the bottom right of the picture
  // display: block;
  // float: right;
}

.option {
  height: 100%;
  overflow: hidden;
  margin: 0.1rem;
  border-radius: 1rem;
}

.how-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.how-it-works {
  margin-top: 2rem;
  width: 60%;
}

// .options {
//   display: flex;
//   justify-content: space-around;
//   flex-wrap: wrap;
// }

.first-block {
  margin-top: 2rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 1rem;
}

.landing h1 {
  font-family: "Alliance1", "Alliance1HeaderFallback", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-feature-settings: unset;
  font-size: 56px !important;
  line-height: 60px !important;
  letter-spacing: -0.03em !important;
  font-weight: 800 !important;
  display: block;

  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 0.2rem;
}

.landing p {
  font-family: "Alliance1", "Alliance1Fallback", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-feature-settings: "ss02" on, "ss01" on;
  font-weight: 400;
  font-size: 20px !important;
  line-height: 28px !important;
  text-align: center;
}

.strapline {
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin-top: 0.1rem;
}

.card-img {
  width: 64px;
  height: 64px;
  padding-left: 1rem;
  padding-top: 1rem;
}
.custom-marker {
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  z-index: 1;
}

::v-deep(.p-timeline-event-content),
::v-deep(.p-timeline-event-opposite) {
  line-height: 1;
}

@media screen and (max-width: 960px) {
  ::v-deep(.customized-timeline) {
    .p-timeline-event:nth-child(even) {
      flex-direction: row !important;

      .p-timeline-event-content {
        text-align: left !important;
      }
    }

    .p-timeline-event-opposite {
      flex: 0;
    }

    .p-card {
      margin-top: 1rem;
    }
  }
}
</style>
