<template>
  <div>
    <Card>
      <template #title>
        <i class="pi pi-user mr-4 p-text-secondary" style="font-size: 2rem"></i>
        Create Game
      </template>
      <template #content>
        <div class="card">
          <div class="p-fluid grid">
            <div class="field col-12 md:col-1">
              <span class="p-float-label">
                <h5>Multiplayer</h5>
                <InputSwitch id="multiplayer" v-model="newGame.multiplayer" />
              </span>
            </div>
            <div class="field col-12 md:col-1">
              <span class="p-float-label">
                <h5>Public</h5>
                <InputSwitch id="public" v-model="newGame.public" />
              </span>
            </div>

            <div class="field col-12 md:col-10"/>

            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText type="text" v-model="newGame.teamName" />
                <label for="inputtext">Team Name</label>
              </span>
            </div>

            <div class="field col-12 md:col-4">
              <span
                >Great team names are short and memorable. Need inspiration? How
                about
                <strong @click="setTeam()" class="reponame-suggestion"
                  >{{ nameRandomSuggestion }}?</strong
                ></span
              >
            </div>
            <div class="field col-12 md:col-4">
              <Button
                class="mr-2 p-button-success"
                v-tooltip.left="'try another'"
                icon="pi pi-refresh"
                @click="updateRandomNameSuggestion()"
              />
              <Button
                class="mr-2 p-button-help"
                v-tooltip.right="'get more'"
                icon="pi pi-cloud-download"
                @click="getNameSuggestions()"
              />
            </div>

            <!-- admin user -->
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <div class="p-inputgroup">
                  <InputText type="text" v-model="newGame.player1" />
                  <label for="inputtext">Player 1 (admin) email</label>
                  <Button label="Self" @click="setMe()" />
                </div>
              </span>
            </div>
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText type="text" v-model="newGame.player1" />
                <label for="inputtext">Player 1 email confirm</label>
              </span>
            </div>
            <div class="field col-12 md:col-4">
              <span
                >Player 1 is the game admin and can launch the game once all
                players are registered</span
              >
            </div>

            <div v-show="newGame.multiplayer" class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText type="text" v-model="newGame.player2" />
                <label for="inputtext">Player 2 email</label>
              </span>
            </div>
            <div v-show="newGame.multiplayer" class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText type="text" v-model="newGame.player2" />
                <label for="inputtext">Player 2 email confirm</label>
              </span>
            </div>
            <div v-show="newGame.multiplayer" class="field col-12 md:col-4" />
            <div v-show="newGame.multiplayer" class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText type="text" v-model="newGame.player3" />
                <label for="inputtext">Player 3 email</label>
              </span>
            </div>
            <div v-show="newGame.multiplayer" class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText type="text" v-model="newGame.player3" />
                <label for="inputtext">Player 3 email confirm</label>
              </span>
            </div>
            <div v-show="newGame.multiplayer" class="field col-12 md:col-4" />

            <div v-show="newGame.multiplayer" class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText type="text" v-model="newGame.player4" />
                <label for="inputtext">Player 4 email</label>
              </span>
            </div>
            <div v-show="newGame.multiplayer" class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText type="text" v-model="newGame.player4" />
                <label for="inputtext">Player 4 email confirm</label>
              </span>
            </div>
            <div v-show="newGame.multiplayer" class="field col-12 md:col-4" />

            <div class="field col-12 md:col-4">
              <Button label="Create" @click="createGame(newGame) && $emit('close-dialog')" />
            </div>

          </div>
        </div>
      </template>
    </Card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "GameCreate",
  // components: {
  //   GameListItem,
  // },
  data() {
    return {
      newGame: {
        multiplayer: true,
        public: false,
        teamName: null,
        player1: null,
        player2: null,
        player3: null,
        player4: null,
      },
    };
  },
  computed: {
    ...mapGetters(["gameItems", "oidcUser", "nameRandomSuggestion"]),
  },
  created() {
    console.log("GameCreate created");
    this.$store.dispatch("getGameItems");
    this.$store.dispatch("getNameSuggestions");
  },
  methods: {
    ...mapActions([
      "createGame",
      "getNameSuggestions",
      "updateRandomNameSuggestion",
    ]),
    setMe() {
      this.newGame.player1 = this.oidcUser.email;
    },
    setTeam() {
      this.newGame.teamName = this.nameRandomSuggestion;
    },
  },
};
</script>
<style scoped lang="scss">
/* .reponame-suggestion {
  color: #0070f3;
  cursor: pointer;
} */
.reponame-suggestion {
  color: var(--text-color-secondary);
  cursor: pointer;
}
</style>