<template>
  <div class="arena-main">
    <ArenaNavbar />
    <ProgressBar :value="gameProgress" class="thin-progress" :showValue="false" />
    <!-- <div id="too_small">
      <h1>Sorry, this screen is too small to display the Arena</h1>
      <h2>Please use a larger screen</h2>
    </div> -->
    <div id="big_enough" class="debug">
      <div class="scores">
        <div class="debug dial">
          <Knob
            v-model="gameScore"
            :min="0"
            :max="knobs.score.max"
            :size="knobs.score.size"
            :valueColor="knobColour('score', gameScore)"
            class="score-knob"
          ></Knob>
          <span>Score</span>
        </div>
        <div class="debug dial">
          <Knob
            v-model="gameScoreTrend"
            :min="0"
            :max="knobs.scoreTrend.max"
            :size="knobs.scoreTrend.size"
            :valueColor="knobColour('scoreTrend', gameScoreTrend)"
            class="score-knob"
          ></Knob>
          <span>Trend</span>
        </div>
        <div class="debug dial">
          <Knob
            v-model="gameOnlineUsers"
            :min="0"
            :max="knobs.onlineUsers.max"
            :size="knobs.onlineUsers.size"
            :valueColor="knobColour('onlineUsers', gameOnlineUsers)"
            class="score-knob"
          ></Knob>
          <span>Online</span>
        </div>
        <div class="debug dial">
          <Knob
            v-model="gameRPS"
            :min="0"
            :max="knobs.rps.max"
            :size="knobs.rps.size"
            :valueColor="knobColour('rps', gameRPS)"
            class="score-knob"
          ></Knob>
          <span>RPS</span>
        </div>
        <div class="debug dial">
          <Knob
            v-model="gameEPS"
            :min="0"
            :max=gameRPS
            :size="knobs.eps.size"
            :valueColor="knobColour('eps', gameEPS)"
            class="score-knob"
          ></Knob>
          <span>EPS</span>
        </div>

        <div class="dial">
          <Knob
            v-model="gameOnlineUserRatios['NotInterestedUser']"
            :min="0"
            :max="knobs.visitor.max"
            :size="knobs.visitor.size"
            :valueColor="knobColour('visitor', gameOnlineUserRatios['NotInterestedUser'])"
            valueTemplate="{value}%"
            class="score-knob"
          ></Knob>
          <span>Visitor</span>
        </div>
        <div class="debug dial">
          <Knob
            v-model="gameOnlineUserRatios['FreeUser']"
            :min="0"
            :max="knobs.free.max"
            :size="knobs.free.size"
            :valueColor="knobColour('free', gameOnlineUserRatios['FreeUser'])"
            valueTemplate="{value}%"
            class="score-knob"
          ></Knob>
          <span>Free</span>
        </div>
        <div class="debug dial">
          <Knob
            v-model="gameOnlineUserRatios['PremiumUser']"
            :min="0"
            :max="knobs.premium.max"
            :size="knobs.premium.size"
            :valueColor="knobColour('premium', gameOnlineUserRatios['PremiumUser'])"
            valueTemplate="{value}%"
            class="score-knob"
          ></Knob>
          <span>Premium</span>
        </div>
        <div class="debug dial">
          <Knob
            v-model="gameOnlineUserRatios['BlueFusionUser']"
            :min="0"
            :max="knobs.blueFusion.max"
            :size="knobs.blueFusion.size"
            :valueColor="knobColour('blueFusion', gameOnlineUserRatios['BlueFusionUser'])"
            valueTemplate="{value}%"
            class="score-knob"
          ></Knob>
          <span>Blue Fusion</span>
        </div>
        <div class="debug dial">
          <!-- const user_types = ["NotInterestedUser", "FreeUser", "PremiumUser", "AntiGravBoardsUser", "BlueFusionUser"] -->
          <Knob
            v-model="gameOnlineUserRatios['AntiGravBoardsUser']"
            :min="0"
            :max="knobs.antiGrav.max"
            :size="knobs.antiGrav.size"
            :valueColor="
              knobColour('antiGrav', gameOnlineUserRatios['AntiGravBoardsUser'])
            "
            valueTemplate="{value}%"
            class="score-knob"
          ></Knob>
          <span>Anti-Grav</span>
        </div>
      </div>
    </div>

    <div id="arena-layout" class="debug">
      <div class="col-4 md:col-4 sm:col-4 chats-panel">
        <ArenaChats />
      </div>
      <div class="col-8 md:col-8 sm:col-8 chats-panel">
        <ArenaChatV2 />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ArenaNavbar from "@/components/arena/ArenaNavbar";
// import ArenaStatus from "@/components/arena//Arena_Status";
import ArenaChatV2 from "@/components/arena//Arena_Chat";
import ArenaChats from "@/components/arena//Arena_Chats";

export default {
  name: "ArenaView",
  props: ["game_id"],
  components: {
    // ArenaStatus,
    ArenaChatV2,
    ArenaChats,
    ArenaNavbar,
  },
  data() {
    return {
      visibleLeft: false,
      knobs: {
        score: {
          min: 0,
          max: 80000,
          size: 75,
          baseColour: "greenyellow",
          highPercentage: 50,
          higerPercentage: 90,
          highColour: "purple",
          higerColour: "red",
          label: "Score",
        },
        scoreTrend: {
          min: 0,
          max: 5000,
          size: 75,
          baseColour: "greenyellow",
          highPercentage: 50,
          higerPercentage: 90,
          highColour: "purple",
          higerColour: "red",
          label: "Score Trend",
        },
        onlineUsers: {
          min: 0,
          max: 1000,
          size: 75,
          baseColour: "greenyellow",
          highPercentage: 50,
          higerPercentage: 90,
          highColour: "purple",
          higerColour: "red",
          label: "Online Users",
        },
        rps: {
          min: 0,
          max: 1000,
          size: 75,
          baseColour: "greenyellow",
          highPercentage: 50,
          higerPercentage: 90,
          highColour: "purple",
          higerColour: "red",
          label: "RPS",
        },
        eps: {
          min: 0,
          max: 1000,
          size: 75,
          baseColour: "greenyellow",
          highPercentage: 50,
          higerPercentage: 90,
          highColour: "purple",
          higerColour: "red",
          label: "EPS",
        },
        visitor: {
          min: 0,
          max: 100,
          size: 75,
          baseColour: "greenyellow",
          highPercentage: 50,
          higerPercentage: 90,
          highColour: "purple",
          higerColour: "red",
          label: "Visitor",
        },
        free: {
          min: 0,
          max: 100,
          size: 75,
          baseColour: "greenyellow",
          highPercentage: 50,
          higerPercentage: 90,
          highColour: "purple",
          higerColour: "red",
          label: "Free",
        },
        premium: {
          min: 0,
          max: 100,
          size: 75,
          baseColour: "greenyellow",
          highPercentage: 50,
          higerPercentage: 90,
          highColour: "purple",
          higerColour: "red",
          label: "Premium",
        },
        blueFusion: {
          min: 0,
          max: 100,
          size: 75,
          baseColour: "greenyellow",
          highPercentage: 50,
          higerPercentage: 90,
          highColour: "purple",
          higerColour: "red",
          label: "Blue Fusion",
        },
        antiGrav: {
          min: 0,
          max: 100,
          size: 75,
          baseColour: "greenyellow",
          highPercentage: 50,
          higerPercentage: 90,
          highColour: "purple",
          higerColour: "red",
          label: "Anti-Grav",
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "gameId",
      "gameProgress",
      "gameScore",
      "gameScoreTrend",
      "gameOnlineUsers",
      "gameRPS",
      "gameEPS",
      "gameOnlineUserRatios",
    ]),
  },

  created() {
    console.log("game_id: " + this.game_id);
    //check if game_id is null from vuex and if so, set it to the prop
    if (this.$store.getters.gameId == null) {
      console.log("game_id is null using url prop");
      this.$store.dispatch("setGameId", this.game_id);
    }
    this.$store.dispatch("getGameState");
    this.$store.dispatch("getGameChats");
    this.$store.dispatch("getGameCloneCommands");
  },

  mounted() {
    this.$store.dispatch("startUpdateGameState");
  },
  beforeUnmount() {
    this.$store.dispatch("stopUpdateGameState");
  },
  methods: {
    knobColour(kind, value) {
      let baseColour = this.knobs[kind].baseColour;
      let highPercentage = this.knobs[kind].highPercentage;
      let higerPercentage = this.knobs[kind].higerPercentage;
      let highColour = this.knobs[kind].highColour;
      let higerColour = this.knobs[kind].higerColour;

      let _percentage = (value / this.knobs[kind].max) * 100;

      if (_percentage < highPercentage) {
        return baseColour;
      } else if (_percentage < higerPercentage) {
        return highColour;
      } else {
        return higerColour;
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dial {
  display: flex;
  flex-direction: column;
  align-items: center;
  // border: solid 1px #3f4b5b;
  padding-right: 1rem;
  padding-left: 1rem;
  // margin: 0.1rem;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.chats-panel {
  border: 1px solid #3f4b5b;
  border-radius: 4px;
  margin-right: 2px;
}

.score-knob {
  font-weight: 700;
}

.scores {
  display: flex;
  //center the items
  justify-content: center;
  margin: 1rem 0;
}
.debug {
  // border: 1px dashed greenyellow;
}

.thin-progress {
  height: 5px;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin: 0;
  padding: 0;
}

#arena-layout {
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;

  font-weight: normal;
  /* add red dashed line */
  // border: 1px dashed red;
  height: 80vh !important;
  // overflow-y: scroll;
}

// @media (min-width: 1024px) {
//   body {
//     display: flex;
//     place-items: center;
//   }

//   #too_small {
//     display: none;
//   }
// }
</style>
