<template>
  <div class="container">
    <div class="item">
      <Construction />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Construction from "@/components/core/Construction.vue";

export default {
  name: "ConstructionView",
  components: {
    Construction,
  },
};
</script>
<style scoped lang="scss">
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
}

.item {
  width: 50%;
  height: 50%;
  //border: 1px dashed blue;
}

/*See https://getbootstrap.com/docs/5.0/layout/breakpoints/ */

@media only screen and (max-width: 992px) {
  .item {
    width: 50%;
    height: 50%;
    //border: 1px dashed blueviolet;
  }
}

@media only screen and (max-width: 768px) {
  .item {
    width: 75%;
    height: 50%;
    //border: 1px dashed yellow;
  }
}

@media only screen and (max-width: 576px) {
  .item {
    width: 80%;
    height: 50%;
    //border: 1px dashed red;
  }
}
</style>
