<template>
  <div>
    <Card>
      <template #title>
        <i class="pi pi-user mr-4 p-text-secondary" style="font-size: 2rem"></i>
        Create Competition
      </template>
      <template #content>
        <div class="card">
          <div class="p-fluid grid">
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText type="text" v-model="newCompetition.compName" />
                <label for="inputtext">Competition Name</label>
              </span>
            </div>

            <div class="field col-12 md:col-4">
              <span
                >Great team names are short and memorable. Need inspiration? How about
                <strong @click="setComp()" class="reponame-suggestion"
                  >{{ nameRandomSuggestion }}?</strong
                ></span
              >
            </div>
            <div class="field col-12 md:col-4">
              <Button
                class="mr-2 p-button-success"
                v-tooltip.left="'try another'"
                icon="pi pi-refresh"
                @click="updateRandomNameSuggestion()"
              />
              <Button
                class="mr-2 p-button-help"
                v-tooltip.right="'get more'"
                icon="pi pi-cloud-download"
                @click="getNameSuggestions()"
              />
            </div>

            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <Calendar
                  v-model="newCompetition.not_before"
                  showIcon
                  iconDisplay="input"
                  showButtonBar
                  dateFormat="yy-mm-dd"
                />
                <!-- <InputText type="text" v-model="newCompetition.not_before" /> -->
                <label for="inputtext">Start</label>
              </span>
            </div>

            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <Calendar
                  v-model="newCompetition.not_after"
                  showIcon
                  iconDisplay="input"
                  showButtonBar
                  dateFormat="yy-mm-dd"
                />
                <!-- <InputText type="text" v-model="newCompetition.not_after" /> -->
                <label for="inputtext">End</label>
              </span>
            </div>

            <!-- admin user -->
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <div class="p-inputgroup">
                  <InputText type="text" v-model="newCompetition.admin" />
                  <label for="inputtext">Admin email</label>
                  <Button label="Self" @click="setMe()" />
                </div>
              </span>
            </div>
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText type="text" v-model="newCompetition.admin" />
                <label for="inputtext">Admin email confirm</label>
              </span>
            </div>
            <div class="field col-12 md:col-4">
              <span
                >Admin can add and remove games from a competition. Only games the admin
                is a player in or public can be added.</span
              >
            </div>

            <div class="field col-12 md:col-4">
              <MultiSelect
                v-model="newCompetition.games"
                :options="gameItems"
                optionLabel="team_name"
                placeholder="Select Games"
                :filter="true"
                class="multiselect-custom"
              >
                <template #value="slotProps">
                  <div
                    class="game-item game-item-value"
                    v-for="option of slotProps.value"
                    :key="option.game_id"
                  >
                    <img src="products/controller_64.png" class="mr-2" width="18" />
                    <div>{{ option.team_name }}</div>
                  </div>
                  <template v-if="!slotProps.value || slotProps.value.length === 0">
                    Select Games
                  </template>
                </template>
                <template #option="slotProps">
                  <div class="game-item">
                    <img src="products/controller_64.png" class="mr-2" width="18" />
                    {{ slotProps.option.team_name }}
                  </div>
                </template>
              </MultiSelect>
            </div>
          </div>
        </div>
        <div>
          <Button
            label="Create"
            @click="createComp(newCompetition) && $emit('close-dialog')"
          />
        </div>
      </template>
    </Card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "CompetitionCreate",
  data() {
    return {
      newCompetition: {
        name: null,
        games: [], // we send the whole game object and extract the id on in createComp
        admin_ids: [],
        player_ids: [], // we will send empty array and populate with players in on creatComp
        created_at: null,
        not_before: null,
        not_after: null,
        public: false,
        updated_at: null,
        leaderboard: [],
      },
      selectedGameIds: null,
    };
  },
  computed: {
    ...mapGetters(["gameItems", "oidcUser", "nameRandomSuggestion"]),
  },
  created() {
    console.log("CompetitionCreate created");
    this.$store.dispatch("getGameItems");
    this.$store.dispatch("getNameSuggestions");
  },
  methods: {
    ...mapActions(["createComp", "getNameSuggestions", "updateRandomNameSuggestion"]),
    setMe() {
      this.newCompetition.admin = this.oidcUser.email;
    },
    setComp() {
      this.newCompetition.compName = this.nameRandomSuggestion;
    },
  },
};
</script>
<style scoped lang="scss">
/* .reponame-suggestion {
  color: #0070f3;
  cursor: pointer;
} */
.reponame-suggestion {
  color: var(--text-color-secondary);
  cursor: pointer;
}

.p-multiselect {
  width: 18rem;
}

::v-deep(.multiselect-custom) {
  .p-multiselect-label:not(.p-placeholder) {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .game-item-value {
    padding: 0.25rem 0.5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: 0.5rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);

    img.flag {
      width: 17px;
    }
  }
}

@media screen and (max-width: 640px) {
  .p-multiselect {
    width: 100%;
  }
}
</style>
