<template>
    <div class="loading-page">
        <div class="loading-page__quadrant">
            <p>Placeholder text</p>
        </div>
        <div class="loading-page__quadrant">
            <p>Placeholder text</p>
        </div>
        <div class="loading-page__quadrant">
            <p>Placeholder text</p>
        </div>
        <div class="loading-page__quadrant">
            <p>Placeholder text</p>
        </div>
        <div class="loading-page__progress-bar">
            <!-- Progress bar goes here -->
            <ProgressSpinner />
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'ArenaLoading',
    data() {
        return {
            // Add your data here
        };
    },
    methods: {
        // Add your methods here
    },
};
</script>
  
<style scoped>
.loading-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.loading-page__quadrant {
    width: calc(50% - 10px);
    height: calc(50% - 10px);
    margin-bottom: 20px;
}

.loading-page__progress-bar {
    width: 100%;
}
</style>