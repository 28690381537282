import axios from 'axios';
import { apiSettings } from '../../../config/api'
import router from '../../../router'
const state = {
  compId: null,
  compLeaderboard: [],
  compItems: [],
  compsInterval: null,  //Used by grid showing all comps
  compLeaderboardInterval: null, //Used by leaderboard to show single comp
  compLeaderboardPublic: [],
  compLeaderboardPublicInterval: null, //Used by leaderboard to show public comp on home page
}
const mutations = {

  UPDATE_COMP_ID(state, payload) {
    state.compId = payload;
  },
  UPDATE_COMP_ITEMS(state, payload) {
    state.compItems = payload;
  },
  UPDATE_COMPS_STATE_INTERVAL(state, interval) {
    state.compsInterval = interval;
  },
  UPDATE_COMP_LEADERBOARD_STATE_INTERVAL(state, interval) {
    state.compLeaderboardInterval = interval;
  },
  UPDATE_COMP_LEADERBOARD_PUBLIC_STATE_INTERVAL(state, interval) {
    state.compLeaderboardPublicInterval = interval;
  },
  UPDATE_COMP_LEADERBOARD(state, payload) {
    state.compLeaderboard = payload;
  },
  UPDATE_COMP_LEADERBOARD_PUBLIC(state, payload) {
    state.compLeaderboardPublic = payload;
  }
}
const actions = {
  setCompId({ commit }, payload) {
    commit('UPDATE_COMP_ID', payload);
  },
  getCompItems({ commit, dispatch }) {
    console.log(`${apiSettings.gamesdays_core_comp_api}`)
    axios.get(`${apiSettings.gamesdays_core_comp_api}`).then((response) => {
      commit('UPDATE_COMP_ITEMS', response.data)

    }).catch((error) => {
      console.log(error)
      dispatch('handleWarning', "Failed to update the comp items", { root: true })
    });
  },
  createComp({ rootState, dispatch }, payload) {
    const adminId = rootState.oidcStore.user
    var now = new Date();
    console.log("payload", payload)
    console.log(payload)
    var admins = []

    if (payload.admin) {
      admins.push(payload.admin)
    }
    if (!admins.includes(adminId.email)) {
      admins.push(adminId.email)
    }

    var game_ids = []
    var player_ids = []
    if (payload.games) {
      payload.games.forEach(game => {
        game_ids.push(game.game_id)
        player_ids.forEach(player => {
          player_ids.push(player)
        });
      });
    }
    else {
      console.warn("No games selected") //TODO: handle this
    }

    // the not_before and not_after are strings in the format "YYYY-MM-DD"
    // we need to move the not after date to the end of the day.
    // we also need to move the not before date to the start of the day.
    // this is because the date picker only allows you to select a date, not a date and time.
    // so we need to make sure that the date range is inclusive of the whole day.

    // not_before
    var not_before = new Date(payload.not_before)
    not_before.setHours(0, 0, 0, 0)
    not_before = not_before.toISOString()

    // not_after
    var not_after = new Date(payload.not_after)
    not_after.setHours(23, 59, 59, 999)
    not_after = not_after.toISOString()



    var comp = {
      "name": payload.compName,
      "game_ids": game_ids,
      "admin_ids": admins,
      "player_ids": player_ids,
      "created_at": now.toISOString(),
      "not_before": not_before,
      "not_after": not_after,
      "public": payload.public,
      "updated_at": now.toISOString(),
      "leaderboard": []
    }
    console.log("comp", comp)
    console.log(comp)
    axios.post(`${apiSettings.gamesdays_core_comp_api}`, comp).then((response) => {
      console.log(response)
      // commit('UPDATE_CART_ITEMS', [])
      dispatch('getCompItems')
    }).catch((error) => {
      console.log(error)
      dispatch('handleError', "Failed to create the comp. Please try again.", { root: true })
    });
  },
  openLeaderboard({ dispatch }, comp_id) {
    console.log(comp_id)
    dispatch('setCompId', comp_id)  //Should we set this here or rely on the route?
    dispatch('getLeaderBoard')
    router.push({ path: `/leaderboard/${comp_id}` })
  },
  getLeaderBoard({ commit, state, dispatch }) {
    console.log("getting leader board compId: " + state.compId)
    axios.get(`${apiSettings.gamesdays_core_comp_api}/${state.compId}`).then((response) => {
      commit('UPDATE_COMP_LEADERBOARD', response.data.leaderboard);
    }).catch((error) => {
      console.log(error)
      dispatch('handleWarning', "Failed to update the leaderboard", { root: true })
    });
  },

  getLeaderBoardPublic({ commit, dispatch }) {
    console.log("getting leader board public")
    axios.get(`${apiSettings.gamesdays_core_comp_api}/public`).then((response) => {
      commit('UPDATE_COMP_LEADERBOARD_PUBLIC', response.data.leaderboard);
    }).catch((error) => {
      console.log(error)
      dispatch('handleWarning', "Failed to update the public leaderboard", { root: true })
    });
  },
  // every 5s dispatch actions which updates the game progress and score
  startUpdateCompsState({ dispatch, commit }) {
    console.log("start updating comps state")
    if (state.compsInterval === null) {
      var stateInterval = setInterval(() => {

        dispatch('getCompItems')

      }, 30000);
      commit('UPDATE_COMPS_STATE_INTERVAL', stateInterval)
    } else {
      console.log("game state update already running")
    }
  },
  stopUpdateCompsState({ state, commit }) {
    console.log("stop updating comps state")
    clearInterval(state.compsInterval)
    commit('UPDATE_COMPS_STATE_INTERVAL', null)
  },

  // every 5s dispatch actions which updates the game progress and score
  startUpdateCompLeaderboardState({ dispatch, commit }) {
    console.log("start updating comp leaderboard state")
    if (state.compLeaderboardInterval === null) {
      var stateInterval = setInterval(() => {

        dispatch('getLeaderBoard')

      }, 30000);
      commit('UPDATE_COMP_LEADERBOARD_STATE_INTERVAL', stateInterval)
    } else {
      console.log("comp leaderboard state update already running")
    }
  },
  stopUpdateCompLeaderboardState({ state, commit }) {
    console.log("stop updating comp leaderboard state")
    clearInterval(state.compLeaderboardInterval)
    commit('UPDATE_COMP_LEADERBOARD_STATE_INTERVAL', null)
  },

  // every 30s dispatch actions which updates the public leaderboard
  startUpdateCompLeaderboardPublicState({ dispatch, commit }) {
    console.log("start updating comp leaderboard public state")
    if (state.compLeaderboardPublicInterval === null) {
      var stateInterval = setInterval(() => {

        dispatch('getLeaderBoardPublic')

      }, 30000);
      commit('UPDATE_COMP_LEADERBOARD_PUBLIC_STATE_INTERVAL', stateInterval)
    } else {
      console.log("comp leaderboard public state update already running")
    }
  },
  stopUpdateCompLeaderboardPublicState({ state, commit }) {
    console.log("stop updating comp leaderboard public state")
    clearInterval(state.compLeaderboardPublicInterval)
    commit('UPDATE_COMP_LEADERBOARD_PUBLIC_STATE_INTERVAL', null)
  }


}
const getters = {
  compId: state => state.compId,
  compItems: state => state.compItems,
  compLeaderboard: state => state.compLeaderboard,
  compLeaderboardPublic: state => state.compLeaderboardPublic
}
const compsModule = {
  state,
  mutations,
  actions,
  getters
}

export default compsModule;