//import { useToast } from "primevue/usetoast";
// const toast = useToast();

const state = {
  message: {}, // {severity: 'error', summary: 'Error', detail: 'an error message', shown: false}
}
const mutations = {
  UPDATE_MESSAGE(state, message) {
    state.message = message;
  },
}
const actions = {
  handleError({ commit }, message) {
    let _message = { severity: 'error', detail: message, summary: 'Error' }
    commit('UPDATE_MESSAGE', _message);
  },
  handleWarning({ commit }, message) {
    let _message = { severity: 'warn', detail: message, summary: 'Warning' }
    commit('UPDATE_MESSAGE', _message);

  },
  handleInfo({ commit }, message) {
    let _message = { severity: 'info', detail: message, summary: 'Info' }
    commit('UPDATE_MESSAGE', _message);

  },
  handleSuccess({ commit }, message) {
    let _message = { severity: 'success', detail: message, summary: 'Success' }
    commit('UPDATE_MESSAGE', _message);
  },

}


const getters = {
  message: state => state.message,
}

const messageModule = {
  getters,
  state,
  mutations,
  actions,
}

export default messageModule;