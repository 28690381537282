<template>
  <div class="leaderboard">
    <Navbar />
    <div class="card">
      <Card>
        <template #title>
          <i class="pi pi-user mr-4 p-text-secondary" style="font-size: 2rem"></i>
          Leaderboard
        </template>
        <template #content>
          <h2>Leaderboard {{ compId }}</h2>

          <div class="LeaderBoard">
            <Leaderboard />
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Leaderboard from "@/components/leaderboard/LeaderboardPrivate";
import Navbar from "@/components/core/Navbar";
export default {
  name: "Leaderboard_Layout",
  props: ["comp_id"],
  components: {
    Leaderboard,
    Navbar,
  },
  computed: {
    ...mapGetters(["compId"]),
  },
  created() {
    //this.$store.dispatch("getGameState");
    console.log("comp_id: " + this.comp_id);
    //check if comp_id is null from vuex and if so, set it to the prop
    if (this.$store.getters.compId == null) {
      console.log("comp_id is null using url prop");
      this.$store.dispatch("setCompId", this.comp_id);
    }
  },

  mounted() {
    //this.$store.dispatch("startUpdateGameState");
  },
  beforeUnmount() {
    //this.$store.dispatch("stopUpdateGameState");
  },
  methods: {
    ...mapActions(["setCompId"]),
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card {
  // background: #ffffff;
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 2rem;
}

h2 {
  font-size: 1em;
  font-weight: bold;
  background: #121fcf;
  background: linear-gradient(to right, #121fcf 0%, #cfc8c5 25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>