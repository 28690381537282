import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/public/HomeView.vue'
import VerifyView from '@/views/public/VerifyView.vue'
import NotFoundView from '@/views/public/NotFoundView.vue'
import SplashView from '@/views/public/SplashView.vue'
import LoadingView from '@/views/public/LoadingView.vue'
import ProductsView from '@/views/public/ProductsView.vue';
import ConstructionView from '@/views/public/ConstructionView.vue'
import FaqView from '@/views/public/FaqView.vue'
// import Harness from '@/views/Harness.vue'
// import Logo from '@/views/Logo.vue'

import LaunchView from '@/views/private/LaunchView.vue'
import CompsView from '@/views/private/CompsView.vue'
import ArenaView from '@/views/private/ArenaView.vue'
import ArenaView3 from '@/views/private/ArenaView3.vue'
import CartView from '@/views/private/CartView.vue';
import PaymentView from '@/views/private/PaymentView.vue';
import ConfirmView from '@/views/private/ConfirmView.vue';
import CheckoutView from '@/views/private/CheckoutView.vue';
import PurchasesView from '@/views/private/PurchasesView.vue';

import ArenaLoadingView from '@/views/private/ArenaLoadingView.vue'
import LeaderBoardView from '@/views/private/LeaderboardView.vue'
import OidcCallback from '@/views/auth/OidcCallback.vue'
import OidcCallbackError from '@/views/auth/OidcCallbackError.vue'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'

import store from '@/store'


const routes = [
  {
    path: '/',
    name: 'Construction',
    component: ConstructionView,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/faq',
    name: 'Faq',
    component: FaqView,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: HomeView,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/splash',
    name: 'Splash',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: SplashView,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/loading',
    name: 'Loading',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: LoadingView,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/404',
    name: 'NotFound',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: NotFoundView,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      isPublic: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/public/AboutView.vue')
  },
  {
    path: '/harness',
    name: 'Harness',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/harness/Harness.vue')
  },
  {
    path: '/harness2',
    name: 'Harness2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/harness/Harness2.vue')
  },
  {
    path: '/harness3',
    name: 'Harness3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/harness/Harness3.vue')
  },
  {
    path: '/harness4',
    name: 'Harness4',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/harness/Harness4.vue')
  },
  {
    path: '/harness5',
    name: 'Harness5',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/harness/Harness5.vue')
  },
  {
    path: '/harness6',
    name: 'Harness6',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/harness/Harness6.vue')
  },
  {
    path: '/inventory',
    component: ProductsView,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/cart',
    component: CartView
  },
  {
    name: 'Payment',
    path: '/payment',
    component: PaymentView
  },
  {
    name: 'Purchases',
    path: '/purchases/:session_id',
    component: PurchasesView,
    props: true
  },
  {
    name: 'Checkout',
    path: '/checkout/:session_id',
    component: CheckoutView,
    props: true
  },
  {
    name: 'ConfirmView',
    path: '/confirm',
    component: ConfirmView
  },
  {
    path: '/arenaold/:game_id',
    component: ArenaView,
    props: true
  },
  {
    path: '/arena/:game_id',
    component: ArenaView3,
    props: true
  },
  {
    path: '/verify',
    component: VerifyView,
    props: true,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/loading/:game_id',
    component: ArenaLoadingView,
    props: true
  },
  {
    path: '/leaderboard/:comp_id',
    component: LeaderBoardView,
    props: true
  },
  {
    path: '/launch',
    component: LaunchView
  },
  {
    path: '/comps',
    component: CompsView
  },
  {
    path: '/',
    redirect: '/inventory',
    meta: {
      isPublic: true
    }
  },
  {
    path: '/oidc-callback', // Needs to match redirectUri (redirect_uri if you use snake case) in you oidcSettings
    name: 'oidcCallback',
    component: OidcCallback
  },
  {
    path: '/oidc-callback-error', // Needs to match redirect_uri in you oidcSettings
    name: 'oidcCallbackError',
    component: OidcCallbackError,
    meta: {
      isPublic: true
    }
  },
  // custom 404 page
  { path: '/:pathMatch(.*)*', component: NotFoundView, meta: { isPublic: true } }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(vuexOidcCreateRouterMiddleware(store))

export default router
