<template>
  <div class="rainbow3_copilot">
    <span></span>
  </div>
</template>
<script>
export default {
  name: "LogoPrompt",
};
</script>
<style scoped lang="scss">
@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

.rainbow3_copilot span::after {
  content: "";
  width: 23px;
  height: 40px;
  // background: #ec7fff;
  // background: #a77bf3;
  background: #FFF947;
  //background: rgb(56, 189, 248);
  display: inline-block;
  animation: cursor-blink 1.5s steps(2) infinite;
  margin-left: 12px;
}

.rainbow3_copilot {
  // background: -webkit-linear-gradient(300deg, #93f5ec 20%, #a77bf3 70%);
  background: -webkit-linear-gradient(300deg, #93f5ec 20%, #FFF947 70%);
  //background: -webkit-linear-gradient(rgb(56, 189, 248), rgb(186, 230, 253));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  font-family: "Alliance1", "Alliance1HeaderFallback", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-feature-settings: unset;

  font-size: 56px !important;
  line-height: 48px !important;
  letter-spacing: -0.1em !important;
  font-weight: 900 !important;

  display: flex;
  align-items: center;
  padding-right: 0.1em;
  padding-bottom: 0.125em;
}

/*See https://www.w3schools.com/css/css_rwd_mediaqueries.asp*/

@media only screen and (max-width: 992px) {
  // Start to look bad here as cursor jumps down
  .rainbow3_copilot {
    font-size: 64px !important;
    letter-spacing: -0.1em !important;
  }
  .rainbow3_copilot span::after {
      /* ration is 0.5833 */
    width: 25px;
    height: 42px; /* Adjust the height to match the font size */
  }
}

@media only screen and (max-width: 768px) {
  // Start to look bad here as cursor jumps down
  .rainbow3_copilot {
    font-size: 48px !important;
    letter-spacing: -0.1em !important;
  }
  .rainbow3_copilot span::after {
    /* ration is 0.5833 */
    width: 22px;
    height: 38px; /* Adjust the height to match the font size */
  }
}

@media only screen and (max-width: 576px) {
  // Start to look bad here as cursor jumps down
  .rainbow3_copilot {
    font-size: 38px !important;
    letter-spacing: -0.1em !important;
  }
  .rainbow3_copilot span::after {
    width: 16px;
    height: 28px; /* Adjust the height to match the font size */
  }
}
</style>
