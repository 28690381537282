<template>
  <div id="main">
    <SystemMessages />
    <router-view></router-view>
  </div>
</template>
<script>
import SystemMessages from "@/components/core/SystemMessages.vue";
export default {
  name: "App",
  components: {
    SystemMessages,
  },
};
</script>
<style lang="scss">
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

html {
  font-size: 14px;
  color: var(--text-color);
}

body {
  background-color: var(--surface-ground);
  margin: 5px; 
  overflow-x: hidden;
  // background: linear-gradient(
  //   180deg,
  //   rgba(85, 48, 152, 0.3) 0%,
  //   rgba(83, 48, 152, 0) 100%
  // );
}

// //mobile
// @media (max-width: 768px) {
//   html {
//     font-size: 12px;
//   }
// }

// //tablet
// @media (min-width: 768px) and (max-width: 1024px) {
//   html {
//     font-size: 14px;
//   }
// }

// //desktop

// @media (min-width: 1024px) {
//   html {
//     font-size: 16px;
//   }
// }
</style>
