<template>
  <div :class="{ 'message-own': isOwn, 'message-from': isFrom }">
    <span v-if="isFrom" :class="{ 'sender-from': isFrom }"
      >{{ capitalizeFirstSender }}
    </span>
    <span :class="{ 'timestamp-own': isOwn, 'timestamp-from': isFrom }">{{
      friendlyTimestamp
    }}</span>

    <br />

    <span class="text">{{ messageItem.text }}</span>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ArenaChatItem",
  props: ["messageItem"],
  methods: {
    ...mapActions(["startGame", "openGame"]),
  },
  computed: {
    ...mapGetters(["oidcUser"]),
    friendlyTimestamp() {
      //convert ISO timestamp to friendly hh:mm
      const date = new Date(this.messageItem.timestamp);
      const friendlyTime = date.toLocaleString("en-us", {
        hour: "2-digit",
        minute: "2-digit",
      });
      return friendlyTime;
    },
    capitalizeFirstSender() {
      console.log(this.messageItem.sender);
      const words = this.messageItem.sender.split(" ");

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }

      return words.join(" ");
    },
    isOwn() {
      if (this.messageItem.sender === this.oidcUser.email) {
        return true;
      } else {
        return false;
      }
    },
    isFrom() {
      if (this.messageItem.sender === this.oidcUser.email) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
div.message-from {
  order: 2;
  background-color: #93f5ec; //#6ebc3b
  color: var(--primary-color-text);
  border: solid 1px #93f5ec;
  margin-left: 0;
  margin-right: auto;

  -webkit-border-radius: 11px;
  /*Safari, Chrome*/
  -moz-border-radius: 11px;
  /*Firefox*/
  border-radius: 11px;
  // width: 80%;
}

div.message-own {
  order: 1;
  background-color: #a77bf3;
  color: var(--primary-color-text);
  border: solid 1px #a77bf3;
  margin-left: auto;
  margin-right: 0;
  -webkit-border-radius: 11px;
  /*Safari, Chrome*/
  -moz-border-radius: 11px;
  /*Firefox*/
  border-radius: 11px;
  // width: 80%;
}

// span.sender-own {
//   //make smaller than message
//   font-size: 1.1rem;
//   color: var(--primary-color-text);

// }

span.sender-from {
  //make smaller than message
  font-size: 1.1rem;
  color: var(--primary-color-text);
  padding-right: 0.2rem;
  font-weight: 650;
}

span.timestamp-own {
  //make smaller than message
  font-size: 1.1rem;
  color: var(--primary-color-text);
  font-weight: 500;
}

span.timestamp-from {
  //make smaller than message
  font-size: 1.1rem;
  color: var(--primary-color-text);
  font-weight: 500;
}

span.text {
  word-wrap: break-word;
}
</style>
