<template>
  <div>
    <div id="too_small">
      <h1>Sorry, this screen is too small to display the Arena</h1>
      <h2>Please use a larger screen</h2>
    </div>
    <div id="arena-layout">
      <div class="container">
        <div class="status-area">
          <ArenaStatus />
        </div>
        <div class="chats-area">
          <ArenaChats />
        </div>
        <div class="tools-area">
          <ArenaToolsMenuV2 />
        </div>
        <div class="clones-area">
          <ArenaCloneMenu />
        </div>
        <div class="chat-area">
          <ArenaChat />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";


import ArenaToolsMenuV2 from "@/components/arena/Arena_ToolsMenu_V2";
import ArenaStatus from "@/components/arena//Arena_Status";
import ArenaChat from "@/components/arena//Arena_Chat";
import ArenaChats from "@/components/arena//Arena_Chats";
import ArenaCloneMenu from "@/components/arena//Arena_CloneMenu";

export default {
  name: "ArenaView",
  props: ["game_id"],
  components: {
    ArenaToolsMenuV2,
    ArenaStatus,
    ArenaChat,
    ArenaChats,
    ArenaCloneMenu,
  },
  data() {
    return {
      visibleLeft: false,
    };
  },
  computed: {
    ...mapGetters(["gameId"]),
  },
  created() {
    console.log("game_id: " + this.game_id);
    //check if game_id is null from vuex and if so, set it to the prop
    if (this.$store.getters.gameId == null) {
      console.log("game_id is null using url prop");
      this.$store.dispatch("setGameId", this.game_id);
    }
    this.$store.dispatch("getGameState");
    this.$store.dispatch("getGameChats");
    this.$store.dispatch("getGameCloneCommands");
  },

  mounted() {
    this.$store.dispatch("startUpdateGameState");
  },
  beforeUnmount() {
    this.$store.dispatch("stopUpdateGameState");
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#arena-layout {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;

  font-weight: normal;
  /* add red dashed line */
  // border: 1px dashed red;
  height: 95vh !important;
  // overflow-y: scroll;
}


@media (min-width: 1024px) {
  body {
    display: flex;
    place-items: center;
  }

  #arena-layout {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 2rem;
    //uncomment for debug
    //border: 1px dashed greenyellow;
  }

  #too_small {
    display: none;
  }
}


@media only screen and (max-width: 1024px) {
  #arena-layout {
    display: none;
  }
}




// Start https://grid.layoutit.com/?id=1zDQkZb
.container {
  display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  // grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  gap: 0px 0px;
  //grid-auto-flow: row;
  grid-template-areas:
    "tools-area tools-area status-area status-area status-area status-area status-area status-area status-area status-area"
    "tools-area tools-area status-area status-area status-area status-area status-area status-area status-area status-area"
    "tools-area tools-area status-area status-area status-area status-area status-area status-area status-area status-area"
    "clones-area clones-area status-area status-area status-area status-area status-area status-area status-area status-area"
    "chats-area chats-area chats-area chat-area chat-area chat-area chat-area chat-area chat-area chat-area"
    "chats-area chats-area chats-area chat-area chat-area chat-area chat-area chat-area chat-area chat-area"
    "chats-area chats-area chats-area chat-area chat-area chat-area chat-area chat-area chat-area chat-area"
    "chats-area chats-area chats-area chat-area chat-area chat-area chat-area chat-area chat-area chat-area"
    "chats-area chats-area chats-area chat-area chat-area chat-area chat-area chat-area chat-area chat-area"
    "chats-area chats-area chats-area chat-area chat-area chat-area chat-area chat-area chat-area chat-area"
    "chats-area chats-area chats-area chat-area chat-area chat-area chat-area chat-area chat-area chat-area";
  overflow: hidden;
}

.status-area {
  grid-area: status-area;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.chats-area {
  grid-area: chats-area;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.tools-area {
  grid-area: tools-area;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.clones-area {
  grid-area: clones-area;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.chat-area {
  grid-area: chat-area;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

// End https://grid.layoutit.com/?id=1zDQkZb
</style>