import axios from 'axios';
// import { loadStripe } from "@stripe/stripe-js";
import { apiSettings } from '@/config/api'
// import router from '@/router'

const state = {
  cartItems: [],
  stripePublicKey: null,
  stripePaymentIntent: null,
  stripeCheckoutSessionUrl: null,
  stripeTracker: null
}

const mutations = {

  UPDATE_STRIPE_CHECKOUT_SESSION_URL(state, payload) {
    state.stripeCheckoutSessionUrl = payload;
  },

  UPDATE_CART_ITEMS(state, payload) {
    state.cartItems = payload;
    localStorage.setItem('cart', JSON.stringify(state.cartItems));
  },
  UPDATE_STRIPE_PUBLIC_KEY(state, payload) {
    state.stripePublicKey = payload;
  },
  UPDATE_STRIPE_PAYMENT_INTENT(state, payload) {
    state.stripePaymentIntent = payload;
  },
  UPDATE_STRIPE_TRACKER(state, payload) {
    state.stripeTracker = payload;
  }

}
const actions = {

  getStripeTracker({ commit, dispatch }, session_id) {
    axios.get(`${apiSettings.gamesdays_core_payment_checkout_session_api}/${session_id}`).then((response) => {
      console.log(response)
      commit('UPDATE_STRIPE_TRACKER', response.data)
      // check if checkout_session_completed and payment_intent_succeeded and  if so empty cart
      if (response.data.checkout_session_completed && response.data.payment_intent_succeeded) {
        commit('UPDATE_CART_ITEMS', [])
      }
    }).catch((error) => {
      console.log(error)
      dispatch('handleWarning', "Failed to get stripe session. Please retry...", { root: true })
    });
  },

  createStripeCheckoutSessionAndOpenSession({ dispatch }) {
    console.log('createStripeCheckoutSessionAndOpenSession')
    dispatch('createStripeCheckoutSession').then(() => {
      dispatch('openStripeCheckoutSession')
    }).catch((error) => {
      console.log(`Failed to create session: ${error}`)
    });
  },


  createStripeCheckoutSession({ rootState, commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      const cartProducts = state.cartItems
      const user = rootState.oidcStore.user
      const protocol = window.location.protocol
      const hostname = window.location.hostname
      const frontend_port = window.location.port
      const frontend_url = `${protocol}//${hostname}:${frontend_port}`
      console.log(`frontend_url: ${frontend_url}`)

      var products = []
      var total = 0

      cartProducts.map((cartProduct) => {

        total += cartProduct.quantity * cartProduct.price
        products.push({
          "product_id": cartProduct.id,
          "quantity": cartProduct.quantity
        })
      });

      var checkout_session_model = {
        "frontend_url": frontend_url,
        "frontend_cart_total": total,
        "products": products
      }
      console.log(`checkout_session_model: ${checkout_session_model}`)
      console.log(`user email: ${user.email}`)


      axios.post(`${apiSettings.gamesdays_core_payment_checkout_session_api}`, checkout_session_model).then((response) => {
        console.log(response)
        console.log(response.data.checkout_session_url)
        // window.location.href = response.data.checkout_session_url
        commit('UPDATE_STRIPE_CHECKOUT_SESSION_URL', response.data.checkout_session_url)
        resolve(response.data.checkout_session_url)
      }).catch((error) => {
        console.log(`Failed to create session: ${error}`)
        dispatch('handleWarning', "Failed to create stripe session. Please retry...", { root: true })
        reject(error);
      });
    });
  },

  // createStripeCheckoutSessionSave({ rootState, commit }) {
  //   return new Promise((resolve, reject) => {
  //     const user = rootState.oidcStore.user
  //     const protocol = window.location.protocol
  //     const hostname = window.location.hostname
  //     const frontend_port = window.location.port
  //     const frontend_url = `${protocol}//${hostname}:${frontend_port}`
  //     console.log(`frontend_url: ${frontend_url}`)

  //     console.log(`user email: ${user.email}`)
  //     let checkout_quantity = {
  //       "quantity": 11,
  //       "frontend_url": frontend_url,
  //       "currency": "GBP",
  //       "amount": 1000,
  //     }

  //     axios.post(`${apiSettings.gamesdays_core_payment_checkout_session_api}`, checkout_quantity).then((response) => {
  //       console.log(response)
  //       console.log(response.data.checkout_session_url)
  //       // window.location.href = response.data.checkout_session_url
  //       commit('UPDATE_STRIPE_CHECKOUT_SESSION_URL', response.data.checkout_session_url)
  //       resolve(response.data.checkout_session_url)
  //     }).catch((error) => {
  //       console.log(`Failed to create session: ${error}`)
  //       reject(error);
  //     });
  //   });
  // },

  openStripeCheckoutSession({ state }) {
    const navigate_to = state.stripeCheckoutSessionUrl
    console.log(`navigate_to: ${navigate_to}`)
    // window.location.href = navigate_to
    console.log(`navigate_to: ${navigate_to}`)
    console.log(`window.location.href: ${window.location.href}`)
    window.location.href = navigate_to
  },

  // //deprecated unless we want to use stripe elements
  // createPaymentIntent({ commit, state, getters, rootState, dispatch }) {
  //   const user = rootState.oidcStore.user
  //   const cartProducts = state.cartItems
  //   console.log(`user email: ${user.email}`)
  //   console.log(cartProducts)
  //   var multiplayerGames = 0
  //   var singleplayerGames = 0
  //   cartProducts.map((cartProduct) => {
  //     if (cartProduct.multiplayer) {
  //       console.log(`cartProduct.multiplayer ${cartProduct.multiplayer}`)
  //       multiplayerGames += cartProduct.quantity * cartProduct.games
  //     }
  //     else if (!cartProduct.multiplayer) {
  //       console.log(`cartProduct.multiplayer ${cartProduct.multiplayer}`)
  //       singleplayerGames += cartProduct.quantity * cartProduct.games
  //     }
  //   });

  //   var purchase = {
  //     "email": user.email,
  //     "amount": getters.cartTotal * 100, //stripe wants the amount in cents
  //     "price": getters.cartTotal,
  //     "currency": "GBP",
  //     "singleplayer_games": singleplayerGames,
  //     "multiplayer_games": multiplayerGames,
  //     "interview_games": 0,
  //     //TODO: add discount stuff
  //     "discount_amount": 0,
  //     "dicount_id": "123"
  //   }
  //   console.log(purchase)
  //   axios.post(`${apiSettings.gamesdays_core_payment_intent_api}`, purchase).then((response) => {
  //     console.log(response)
  //     commit('UPDATE_CART_ITEMS', [])
  //     commit('UPDATE_STRIPE_PAYMENT_INTENT', response.data.client_secret)
  //     // dispatch('openPaymentDialog')
  //     router.push({ name: 'Payment' })
  //   }).catch((error) => {
  //     console.log(error)
  //     dispatch('handleWarning', "Failed to create payment intnet. Please retry...", { root: true })
  //   });
  // },

  getStripePublicKey({ commit, dispatch }) {
    axios.get(`${apiSettings.gamesdays_core_payment_config_api}`).then((response) => {
      let publishable_key = response.data.publishable_key
      console.log(`publishable_key: ${publishable_key}`)
      commit('UPDATE_STRIPE_PUBLIC_KEY', response.data.publishable_key)

    }).catch((error) => {
      console.log(error)
      dispatch('handleError', "Failed to get stripe public key. Please retry...", { root: true })
    });
  },
  getCartItems({ commit }) {
    if (localStorage.getItem('cart')) {
      let items = JSON.parse(localStorage.getItem('cart'));
      commit('UPDATE_CART_ITEMS', items)
    } else {
      commit('UPDATE_CART_ITEMS', [])
    }
  },
  addCartItem({ commit, state }, cartItem) {
    console.log("add: " + cartItem)

    const cartProducts = state.cartItems
    const newCartProduct = {
      id: cartItem.id,
      name: cartItem.name,
      description: cartItem.description,
      price: cartItem.price,
      currency: cartItem.currency,
      active: cartItem.active,
      games: cartItem.games,
      multiplayer: cartItem.multiplayer,
      image_tag: cartItem.image_tag,
      //This not from product model
      quantity: 1
    };
    let cartProductExists = false;
    cartProducts.map((cartProduct) => {
      if (cartProduct.id === newCartProduct.id) {
        cartProduct.quantity++;
        cartProductExists = true;
      }
    });
    if (!cartProductExists) cartProducts.push(newCartProduct);
    commit('UPDATE_CART_ITEMS', cartProducts)
  },

  // checkoutCartItems({ commit, state, getters, rootState, dispatch }) {
  //   const user = rootState.oidcStore.user
  //   const cartProducts = state.cartItems
  //   console.log(`user email: ${user.email}`)
  //   console.log(cartProducts)
  //   var multiplayerGames = 0
  //   var singleplayerGames = 0
  //   cartProducts.map((cartProduct) => {
  //     if (cartProduct.multiplayer) {
  //       console.log(`cartProduct.multiplayer ${cartProduct.multiplayer}`)
  //       multiplayerGames += cartProduct.quantity * cartProduct.games
  //     } else if (!cartProduct.multiplayer) {
  //       console.log(`cartProduct.multiplayer ${cartProduct.multiplayer}`)
  //       singleplayerGames += cartProduct.quantity * cartProduct.games
  //     }
  //   });
  //   //TODO: sort this purchase stuff out when we integrate with stripe/paypal etc..
  //   //We validate this on the backend to check nothing funny has happened
  //   var now = new Date();

  //   var purchase = {
  //     "email": user.email,
  //     "purchase_amount": getters.cartTotal,
  //     "price": getters.cartTotal,
  //     "singleplayer_games": singleplayerGames,
  //     "multiplayer_games": multiplayerGames,
  //     "purchase_at": now.toISOString()
  //   }

  //   axios.post(`${apiSettings.gamesdays_core_purchase_api}`, purchase).then((response) => {
  //     console.log(response)
  //     commit('UPDATE_CART_ITEMS', [])
  //   }).catch((error) => {
  //     console.log(error)
  //     dispatch('handleError', "Failed to create checkout. Please retry...", { root: true })
  //   });
  // },

  removeCartItem({ commit, state }, cartItem) {
    console.log("delete: " + cartItem)
    let cartProducts = state.cartItems
    cartProducts.map((cartProduct) => {
      if (cartProduct.product_id === cartItem && cartProduct.quantity > 1) {
        cartProduct.quantity--;
      } else if (cartProduct.product_id === cartItem && cartProduct.quantity === 1) {
        const cartIndexToRemove = cartProducts.findIndex(cartProduct => cartProduct.product_id === cartItem);
        cartProducts.splice(cartIndexToRemove, 1);
      }
    });
    commit('UPDATE_CART_ITEMS', cartProducts)
  },
  removeAllCartItems({ commit }) {
    commit('UPDATE_CART_ITEMS', [])
  }
}
const getters = {
  stripeCheckoutSessionUrl: state => state.stripeCheckoutSessionUrl,
  stripePublicKey: state => state.stripePublicKey,
  stripePaymentIntent: state => state.stripePaymentIntent,
  stripeTracker: state => state.stripeTracker,
  cartItems: state => state.cartItems,
  cartTotal: state => {
    return state.cartItems.reduce((acc, cartItem) => {
      //   return (cartItem.quantity * cartItem.price) + acc;
      // }, 0).toFixed(2);
      return (cartItem.quantity * cartItem.price) + acc;
    }, 0);
  },
  cartQuantity: state => {
    const quant = state.cartItems.reduce((acc, cartItem) => {
      return cartItem.quantity + acc;
    }, 0);
    //convert quantity to string and return
    return quant.toString();
  }
}

const cartModule = {
  state,
  mutations,
  actions,
  getters
}
export default cartModule;

